import React from 'react'
import {Box, Button, Container, Typography} from '@mui/material'
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import Tabs from '../CardsTab/Tabs'
import BlurImageWrapper, { opacities, orientations } from '../BlurImageWrapper'
import SearchInput from '../SearchInput'
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg'
import { useScreenDetector } from '../../hooks/useScreenDetector'
import './styles.css'
import TagButton from '../TagButton'
import { getHrefFromGuide } from '../../utils/guias'

const GuidesTabs = ({ tabsData }) => {
    const [activeTab, setActiveTab] = React.useState(0)
    const [rowExpanded, setRowExpanded] = React.useState(null)
    const { isMobile } = useScreenDetector();
    
    if (!tabsData?.length) return null

    const allGuides = tabsData
        .map(element => element.countries)
        .filter(Boolean)
        .reduce((a, b) => [...a, ...b])
    
    const tabs = [
        {
            name: 'Ver todos',
            countries: allGuides,
        },
        ...tabsData,
    ]

    const onChangeTab = (index) => (evt, tabIndex) => {
        setRowExpanded(null)
        const tab = index !== undefined ? index : tabIndex
        setActiveTab(tab)
    }

    const onExpandRow = (index) => {
        if (index === rowExpanded) {
            setRowExpanded(null)
        } else {
            setRowExpanded(index)
        }
    }

    const NoResultsContainer = () => (
        <Box sx={{py: '3rem', px: '2rem', textAlign: 'center'}}>
            <Typography>
                No se encontraron resultados, intenta con otra opción
            </Typography>
        </Box>
    )

    const TagsBox = ({ tags, index }) => (
        <Box sx={{
            display: {
                xs: rowExpanded === index ? 'flex' : 'none',
                md: 'flex',
            },
            flexWrap: 'wrap',
            gap: '1rem',
            py: '1rem',
            px: {xs: '.5rem', md: '1.5rem'},
        }}>
            {
                tags.map((tag, index) => {
                    const [guide] = tag.destinationGuides;
                    return (
                        <TagButton
                            key={`${tag.name}${index}`}
                            text={tag.name}
                            href={getHrefFromGuide(guide)}
                            sx={{ height: '2rem' }}
                        />
                    )
                })
            }
        </Box>
    )

    const RowCard = ({ country, index }) => {
        const imageURl = country?.cities[0]?.destinationGuides[0]?.imagen
        return (
        <BlurImageWrapper
            imageUrl={imageURl}
            opacity={opacities.darker}
            orientation={orientations.toTopLeft}
            styles={{
                borderRadius: `
                    10px
                    ${isMobile ? '10px' : '0'}
                    ${rowExpanded === index || !isMobile ? '0' : '10px'}
                    ${rowExpanded === index ? '0' : '10px'}`,
            }}
        >
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                py: '1.2rem',
                px: '1rem',
            }}>
                <Box>
                    <Typography
                        color='primary.contrastText'
                        sx={{
                            fontFamily: 'Inter',
                            fontSize: 24,
                            fontWeight: 700
                        }}
                    >
                        {country.name}
                    </Typography>
                    <Typography
                        color='primary.contrastText'
                        sx={{
                            fontFamily: 'Inter',
                            fontSize: 16,
                        }}
                    >
                        {`${country.cities.length} destinos`}
                    </Typography>
                </Box>
                <Button
                    onClick={() => onExpandRow(index)}
                    sx={{
                        display: {xs: 'block', md: 'none'},
                        bottom: -20,
                        right: -20,
                    }}
                >
                    {
                        rowExpanded === index ?
                            <KeyboardArrowUp sx={{ color: 'white' }} /> :
                            <KeyboardArrowDown sx={{color: 'white'}} />
                    }
                </Button>
            </Box>
        </BlurImageWrapper>
    )
}
    
    return (
        <Container sx={{ my: '4rem'}}>
            <Typography color='secondary' variant='subtitle' sx={{
                fontWeight: '700',
                textAlign: {xs: 'center', md: 'left'}
            }}>
                Nuestras guías de viaje
            </Typography>
            <Box sx={{ mt: '1.5rem' }}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    justifyContent: 'space-between',
                    width: '100%',
                }}>
                    <Box sx={{
                        order: {xs: 2, md: 1},
                        width: {xs: '100%', md: '77%'}
                    }}>
                        <Tabs activeTab={activeTab} tabs={tabs} onChangeTab={onChangeTab} />
                    </Box>
                    <Box sx={{
                        mb: {xs: '1rem', md: 0},
                        order: { xs: 1, md: 2 },
                        px: { xs: '3rem', md: 0 },
                        width: {xs: '100%', md: '23%'}
                    }}>
                        <SearchInput
                            placeHolder='Busca tu guía de viaje'
                            SearchIcon={SearchIcon}
                            source='guides'
                            variant='inline'
                        />
                    </Box>
                </Box>
                <ul className='list_container'>
                    {
                        tabs[activeTab]?.countries?.length ? (
                            <>
                                {
                                    tabs[activeTab].countries?.map((country, index) =>
                                        country?.cities?.length ? (
                                            <li
                                                className='list-item'
                                                key={country.name}
                                            >
                                                <Box sx={{
                                                    display: 'flex',
                                                    flexDirection: {xs: 'column', md: 'row'}
                                                }}>
                                                    <Box sx={{
                                                        height: '100',
                                                        width: { xs: '100%', md: '20%' }
                                                    }}>
                                                        <RowCard country={country} index={index} />
                                                    </Box>
                                                    <TagsBox index={index} tags={country.cities} />
                                                </Box>
                                            </li>
                                        ) : null
                                    )
                                }
                            </>
                        ): <NoResultsContainer />
                    }
                </ul>
            </Box>
        </Container>
    )
}

export default GuidesTabs;
