import { red } from '@mui/material/colors'
import { createTheme } from '@mui/material/styles'
import { esES as coreEsES } from '@mui/material/locale'
import { esES } from '@mui/x-date-pickers/locales'

// A custom theme for this app
const theme = createTheme(
    {
        palette: {
            primary: {
                main: '#40C5E8',
                contrastText: '#ffffff'
            },
            secondary: {
                main: '#223F7F',
                contrastText: '#ffffff'
            },
            grayText: {
                main: '#666B6E',
                contrastText: '#ffffff'
            },
            error: {
                main: red.A400,
                contrastText: '#ffffff'
            },
            blackArticle: {
                main: '#3E3A39'
            },
            white: {
                main: '#ffffff'
            }
        },
        components: {
            MuiButton: {
                styleOverrides: {
                    // Name of the slot
                    root: {
                        borderRadius: 50,
                        color: '#fff',
                        textTransform: 'none'
                    }
                }
            },
            MuiImageListItemBar: {
                styleOverrides: {
                    title: {
                        fontSize: '20px'
                    },
                    titleWrap: {
                        paddingRight: '0px'
                    }
                }
            },
            MuiTab: {
                styleOverrides: {
                    root: {
                        '&.Mui-selected': {
                            fontWeight: 700,
                            padding: '0 1rem',
                            borderBottom: '2px solid #40C5E8'
                        }
                    }
                }
            }
        },
        typography: {
            fontFamily: ['Poppins, Inter'].join(','),
            h1: {
                fontSize: 22,
                fontFamily: 'Poppins',
                fontWeight: 'bolder',
                lineHeight: 1.5,
                '@media (min-width: 481px)': {
                    fontSize: 48
                }
            },
            h2: {
                fontSize: 16,
                fontFamily: 'Poppins',
                fontWeight: 'bold',
                lineHeight: 1.2,
                '@media (min-width: 481px)': {
                    fontSize: 24,
                    fontWeight: 'bolder'
                }
            },
            h3: {
                fontSize: 32,
                fontFamily: 'Poppins',
                fontWeight: 'bolder',
                lineHeight: 1.5,
                '@media (min-width: 481px)': {
                    fontSize: 72
                }
            },
            h5: {
                fontSize: 20,
                fontFamily: 'Poppins',
                fontWeight: 'bolder',
                lineHeight: 1.8
            },
            title: {
                fontSize: 32,
                fontFamily: 'Poppins',
                fontWeight: 'bold',
                lineHeight: 1.5
            },
            title2: {
                fontFamily: 'Poppins',
                fontSize: 18,
                fontWeight: 'bold',
                lineHeight: 1.5,
                '@media (min-width: 481px)': {
                    fontSize: 20
                }
            },
            subtitle: {
                fontSize: 22,
                fontFamily: 'Poppins',
                fontWeight: 'bolder',
                lineHeight: 1.5,
                '@media (min-width: 481px)': {
                    fontSize: 32
                }
            },
            subtitle1: {
                fontSize: 16,
                fontFamily: 'Poppins',
                fontWeight: 'bold',
                lineHeight: 1.2,
                '@media (min-width: 481px)': {
                    fontSize: 24,
                    fontWeight: 'bolder'
                }
            },
            subtitle2: {
                fontSize: 22,
                fontFamily: 'Poppins',
                fontWeight: 'bolder',
                lineHeight: 1.5,
                '@media (min-width: 481px)': {
                    fontSize: 48
                }
            },
            textBody: {
                fontSize: 14,
                fontFamily: 'Poppins',
                fontWeight: 400,
                lineHeight: 1.7,
                '@media (min-width: 481px)': {
                    fontSize: 20
                }
            },
            textBody1: {
                fontSize: 14,
                fontFamily: 'Poppins',
                fontWeight: 400,
                lineHeight: 1.7,
                '@media (min-width: 481px)': {
                    fontSize: 18,
                    fontWeight: 600
                }
            },
            body: {
                fontSize: 12,
                fontFamily: 'Poppins',
                fontWeight: 400,
                lineHeight: 1.5,
                '@media (min-width: 481px)': {
                    fontSize: 16
                }
            },
            body2: {
                fontSize: 12,
                fontFamily: 'Poppins',
                fontWeight: 500,
                lineHeight: 1.5
            },
            body3: {
                fontSize: 18,
                fontFamily: 'Inter',
                fontWeight: 600,
                lineHeight: 1.5
            },
            body4: {
                fontSize: 14,
                fontFamily: 'Poppins',
                fontWeight: 500,
                lineHeight: 1.5,
                '@media (min-width: 481px)': {
                    fontSize: 18
                }
            },
            subtitle3: {
                fontSize: 12,
                fontFamily: 'Poppins',
                fontWeight: 600,
                lineHeight: 1.5,
                '@media (min-width: 481px)': {
                    fontSize: 14
                }
            }
        }
    },
    esES,
    coreEsES
)

export default theme
