import React from "react"
import { Box, Button, Collapse, Typography } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

const faq = {
    title: "¿Lorem ipsum dolor sit amet consectetur?",
    content: "Lorem ipsum dolor sit amet consectetur. Facilisi varius enim aliquam lobortis in vitae. Blandit faucibus interdum risus volutpat. Neque viverra lectus adipiscing porttitor in. Habitant ornare ultrices pulvinar tellus vulputate sed egestas enim vitae. Et tellus elementum interdum sit non porttitor. Nulla tristique phasellus morbi dui aliquet pretium a sit massa. Leo pretium etiam velit accumsan dolor vestibulum vitae arcu arcu. Tincidunt id enim purus senectus. Sed quam sem eleifend condimentum viverra diam vulputate. Pretium pellentesque in purus nisi. Sollicitudin pellentesque mi sed nulla. Placerat proin suspendisse dapibus.",
}

const mockFaqs = Array(4).fill(faq);

const FAQs = ({ faqs = mockFaqs }) => {
    const [expandedFaqs, setExpandedFaqs] = React.useState([faqs.length - 1])

    const onCollapse = (index) => {
        if (expandedFaqs.includes(index)) {
            setExpandedFaqs(current => current.filter(item => item !== index))
        } else {
            setExpandedFaqs(current => [...current, index])
        }
    }

    return (
        <Box sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            mt: "3rem",
            width: "100%"
        }}>
            <Box sx={{
                width: { xs: "100%", md: "30%" },
                pr: { xs: 0, md: "3rem" },
            }}>
                <Typography
                    color="secondary"
                    component="h2"
                    sx={{
                        borderBottom: "1px solid #223F7F",
                        fontSize: 24,
                        fontFamily: 'Poppins',
                        fontWeight: 700,
                        lineHeight: 1.2,
                        pr: { xs: 0, md: "50%" },
                        mb: "2rem",
                        textAlign: {xs: "center", md: "left"}
                    }}
                >
                    Preguntas frecuentes
                </Typography>
            </Box>
            <Box sx={{ width: { xs: "100%", md: "70%" } }}>
                {
                    faqs.map((faq, index) => (
                        <Box key={`${faq.title}${index}`} mb={2}>
                            <Box sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                width: "100%"
                            }}>
                                <Typography
                                    component="h3"
                                    color={
                                        expandedFaqs.includes(index) ? "secondary" : "primary"
                                    }
                                    sx={{
                                        fontFamily: "Poppins",
                                        fontSize: "18px",
                                        fontWeight: 700,
                                        mb: ".5rem",
                                    }}
                                >
                                    {faq.title}
                                </Typography>
                                <Button
                                    variant="text"
                                    sx={{  color: "#223F7F", pr: 0 }}
                                    onClick={() => onCollapse(index)}
                                >
                                    {expandedFaqs.includes(index) ?
                                        <ExpandLess /> :
                                        <ExpandMore color="primary" />
                                    }
                                </Button>
                            </Box>
                            <Collapse
                                in={expandedFaqs.includes(index)}
                                timeout='auto'
                                unmountOnExit
                            >
                                <Box
                                    sx={{
                                        color: "#666B6E",
                                        display: "flex",
                                        flexDirection: "column",
                                        fontFamily: "Inter",
                                        fontSize: "1rem",
                                    }}
                                >
                                    <Typography>
                                        {faq.content}
                                    </Typography>
                                </Box>
                            </Collapse>
                        </Box>
                    ))
                }
            </Box>
        </Box>
    )
}

export default FAQs
