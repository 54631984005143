import {
    Box,
    Container,
    Skeleton
} from '@mui/material'

export default function GuiasBlogSkeleton () {
    return (
        <Box>
            <Skeleton variant='rectangular' height={250} sx={{ mb: '5rem' }} />
            <Container>
                <Skeleton width="50%" sx={{mb: '1rem'}}/>
                <Skeleton width="90%" sx={{mb: '3rem'}} />
                <Skeleton width="80%" sx={{mb: '1rem'}} />
                <Skeleton width="80%" sx={{ mb: '1rem' }} />
                <Skeleton variant='rectangular' height={220} />
                <Skeleton width="90%" sx={{ mb: '3rem' }} />
                <Skeleton width="50%" sx={{mb: '1rem'}}/>
                <Skeleton width="90%" sx={{mb: '3rem'}} />
                <Skeleton width="80%" sx={{mb: '1rem'}} />
            </Container>
        </Box>
    )
}
