import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    tours: [
    {
      id: 1,
      imageUrl: 'images/kalalau.png',
      ivaIncluded: true,
      languages: "Ingles",
      time: "5 horas",
      cancelable: true,
      rate: null,
      price: 150.5,
      reviews: null,
      description: ``,
      title: 'Sendero Kalalau y gastronomía local',
      date: "2024-07-21",
      type: "Paquete premium(150€)",
      assistants: "9",
      moreDetails: false,
      edit: true,
      remove: true,
      "optionals": {
        "id": "10",
        "name": "Demo Optional 1",
        "pax": [
            {
                "id": 4,
                "name": "Infante",
                "total": 2,
                "price": 90,
                "totalQuote": 180
            },
            {
                "id": 5,
                "name": "Adulto",
                "total": 5,
                "price": 100,
                "totalQuote": 500
            }
        ]
    }
    },
    {
      id: 2,
      imageUrl: 'images/kalalau.png',
      ivaIncluded: true,
      languages: "Ingles",
      time: "5 horas",
      cancelable: true,
      rate: null,
      price: 150.5,
      reviews: null,
      description: ``,
      title: 'Sendero Kalalau y gastronomía local',
      date: "2024-07-21",
      type: "Paquete premium(150€)",
      assistants: "6",
      moreDetails: false,
      edit: true,
      remove: true,
      "optionals": {
        "id": "10",
        "name": "Demo Optional 1",
        "pax": [
            {
                "id": 4,
                "name": "Infante",
                "total": 2,
                "price": 90,
                "totalQuote": 180
            },
            {
                "id": 5,
                "name": "Adulto",
                "total": 5,
                "price": 100,
                "totalQuote": 500
            }
        ]
    }
    }
  ],

  assistants: [
    {
      id: 'A1',
      name: 'Any',
      lastName: 'Any',
      passport: '000001',
      tours: [1, 2]

    },
    {
      id: 'A2',
      name: 'Any',
      lastName: 'Any',
      passport: '000001',
      tours: [1, 2]

    },
    {
      id: 'A3',
      name: 'Any',
      lastName: 'Any',
      passport: '000001',
      tours: [1, 2]

    }
  ]
}

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    add: (state, action) => {
      state.tours.push(action.payload);
    },
    remove: (state, action) => {
      console.log(action)
      state.tours = state.tours.filter(tour => tour.id !== action.payload)
    },
  },
})

export const { add, remove } = cartSlice.actions

export const selectTours = (state) => state.cart.tours;

export const selectAssitants = (state) => state.cart.assistants;

export default cartSlice.reducer