import React, { useState } from 'react'
import {
    AppBar,
    Box,
    Collapse,
    Container,
    Divider,
    IconButton,
    Link,
    Menu,
    MenuItem,
    Toolbar,
    Typography
} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'

import SearchInput from '../SearchInput'
import UpdateTourModal from '../UpdateTourModal'

import CheckoutContext from '../CheckoutContext/checkoutContext'
import { useScreenDetector } from '../../hooks/useScreenDetector'
import { remove, selectTours } from '../../app/features/cartSlice'
import { useDispatch, useSelector } from 'react-redux'
import useGetToken from '../../hooks/useGetToken'

import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg'
import { ReactComponent as UserIcon } from '../../assets/icons/user.svg'
import { ReactComponent as CartIcon } from '../../assets/icons/cart.svg'
import { ReactComponent as SearchOpenIcon } from '../../assets/icons/search-open.svg'
import { ReactComponent as CloseCircle } from '../../assets/icons/close-circle.svg'
import CartModal from './CartModal'

import './styles.css'

const Header = ({ logoImgSource, pages }) => {
    useGetToken()
    const [openSearch, setOpenSearch] = useState(false)
    const [anchorElNav, setAnchorElNav] = React.useState(null)
    const [openCartModal, setOpenCartModal] = useState(false)
    const [currentPage] = window.location.pathname.split('/').filter(Boolean)
    const { isMobile } = useScreenDetector()
    const cartTours = useSelector(selectTours)
    const [open, setOpen] = useState(false)
    const [selectedTour, setSelectedTour] = useState(null)
    const dispatch = useDispatch()

    const handleRemoveTour = (id) => dispatch(remove(id))

    const handleEditTour = (id) => {
        setSelectedTour(cartTours.find((tour) => tour.id === id))
        setOpen(true)
    }

    const handleClose = () => setOpen(false)

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget)
    }

    const handleOpenSearch = () => {
        setOpenSearch(!openSearch)
    }

    const handleCloseNavMenu = () => {
        setAnchorElNav(null)
    }

    const handleOpenCartModal = () => {
        setOpenCartModal(!openCartModal)
    }

    return (
        <CheckoutContext.Provider
            value={{
                handleEditTour: handleEditTour,
                handleRemoveTour: handleRemoveTour
            }}>
            <Box>
                <AppBar
                    sx={{
                        borderBottom: '2px solid #EEEEEE',
                        px: { xs: '1%', md: '6%', lg: '8%' },
                        py: '8px',
                        position: 'initial',
                        bgcolor: 'white!important'
                    }}>
                    <Toolbar
                        sx={{
                            justifyContent: 'space-between'
                        }}>
                        <Box
                            sx={{
                                flexGrow: 1,
                                display: { xs: 'contents', md: 'none' }
                            }}>
                            {isMobile && (
                                <>
                                    <IconButton
                                        sx={{ color: 'secondary.main' }}
                                        size="large"
                                        aria-label="account of current user"
                                        aria-controls="menu-appbar"
                                        aria-haspopup="true"
                                        onClick={handleOpenNavMenu}>
                                        <MenuIcon />
                                    </IconButton>
                                    {Boolean(anchorElNav) && (
                                        <Menu
                                            id="menu-appbar"
                                            anchorEl={anchorElNav}
                                            keepMounted
                                            open={Boolean(anchorElNav)}
                                            onClose={handleCloseNavMenu}>
                                            {pages.map((page) => (
                                                <MenuItem key={page.text}>
                                                    <Link
                                                        href={page.url}
                                                        underline="none">
                                                        <Typography
                                                            color="primary.contrastText"
                                                            sx={{
                                                                fontSize:
                                                                    '16px',
                                                                fontWeight: 600,
                                                                lineHeight:
                                                                    '21px'
                                                            }}>
                                                            {page.text}
                                                        </Typography>
                                                    </Link>
                                                </MenuItem>
                                            ))}
                                            <MenuItem
                                                onClick={handleCloseNavMenu}
                                                sx={{
                                                    justifyContent: 'center'
                                                }}>
                                                <CloseCircle
                                                    style={{
                                                        width: 30,
                                                        height: 30
                                                    }}
                                                />
                                            </MenuItem>
                                        </Menu>
                                    )}
                                </>
                            )}
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'row',
                                justifyContent: 'flex-start'
                            }}>
                            <Link href="/">
                                <img
                                    alt="toursprivados.com"
                                    src={logoImgSource}
                                    style={{
                                        width: '8rem',
                                        height: '4rem',
                                        paddingTop: isMobile ? '8px' : ''
                                    }}
                                />
                            </Link>
                        </Box>

                        <Box
                            sx={{
                                flexGrow: 1,
                                display: { xs: 'none', md: 'flex' },
                                justifyContent: 'center',
                                alignItems: 'flex-start',
                                marginTop: 1,
                                marginLeft: '8%',
                                gap: 2
                            }}>
                            {pages.map((page) => (
                                <MenuItem
                                    key={page.text}
                                    sx={{
                                        color: 'white',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        my: 1,
                                        px: '24px'
                                    }}>
                                    <Link href={page.url} underline="none">
                                        <Typography
                                            color="secondary"
                                            textAlign="center"
                                            sx={{
                                                fontSize: '16px',
                                                fontWeight: 600,
                                                lineHeight: '21px'
                                            }}>
                                            {page.text}
                                        </Typography>
                                    </Link>
                                    {page.url.includes(currentPage) ? (
                                        <Divider
                                            sx={{
                                                backgroundColor: '#223F7F',
                                                height: 2,
                                                mt: '.5rem',
                                                width: '40%'
                                            }}
                                        />
                                    ) : null}
                                </MenuItem>
                            ))}
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'normal',
                                mb: 0.5
                            }}>

                            <IconButton
                                onClick={handleOpenSearch}
                                aria-label="search"
                                sx={{
                                    px: { xs: '6px', md: '12px' },
                                    ml: '8px'
                                }}>
                                {openSearch ? (
                                    <SearchOpenIcon
                                        style={{ width: 28, height: 28 }}
                                    />
                                ) : (
                                    <SearchIcon
                                        style={{ width: 24, height: 24 }}
                                    />
                                )}
                            </IconButton>
                            {isMobile ? (
                                <IconButton
                                    aria-label="account of current user"
                                    sx={{
                                        px: { xs: '6px', md: '12px' }
                                    }}
                                    href="/cart">
                                    <CartIcon
                                        style={{ width: 36, height: 36 }}
                                    />
                                </IconButton>
                            ) : (
                                <IconButton
                                    aria-label="account of current user"
                                    sx={{
                                        px: { xs: '6px', md: '12px' }
                                    }}
                                    onClick={handleOpenCartModal}>
                                    <CartIcon
                                        style={{ width: 36, height: 36 }}
                                    />
                                </IconButton>
                            )}
                        </Box>
                    </Toolbar>
                </AppBar>
                <Collapse in={openSearch}>
                    <Box
                        sx={{
                            bgcolor: 'secondary.main',
                            display: 'ruby-text'
                        }}>
                        <Container>
                            <SearchInput styles={{ maxWidth: '100%' }} />
                        </Container>
                    </Box>
                </Collapse>

                {openCartModal && <CartModal cartTours={cartTours} />}
            </Box>
            {selectedTour && (
                <UpdateTourModal
                    open={open}
                    handleClose={handleClose}
                    tour={selectedTour}
                />
            )}
        </CheckoutContext.Provider>
    )
}

export default Header
