import React from "react"
import {
    Box,
    Container,
    Divider,
    Link,
    List,
    ListItem,
} from "@mui/material"
import PayMethodsSection from "./PayMethodsSection"
import FooterImage from "../../assets/images/trekzy-footer-2.png"
import { ReactComponent as TrekzyLogo } from "../../assets/images/trekzy-logo-white.svg"

const DeskopFooter = () => (
    <Box sx={{
        backgroundColor: "#223F7F",
        width: "100%",
        mb: {xs: '156px', md: 0}
    }}>
        <Container sx={{
            display: "flex",
            alignItems: "center",
            pt: "6rem",
            pb: "2rem",
        }}>
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                width: "65%",
            }}>
                <TrekzyLogo
                    style={{
                        height: 40,
                        width: 125,
                        marginBottom: ".8rem"
                    }}
                />
                <Divider
                    aria-hidden="true"
                    sx={{
                        backgroundColor: '#FFFFFF',
                        height: 2,
                        mb: "2rem",
                        width: 150
                    }}
                />
                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: 'baseline'
                }}>
                    <PayMethodsSection />
                    <Box sx={{
                        display: {xs: 'none', md: 'flex'}
                    }}>
                        <Link
                            color="primary.contrastText"
                            underline='none'
                        >Política de Privacidad</Link>
                    </Box>
                </Box>
                
            </Box>
            <Box
                sx={{
                    width: "35%",
                    display: "flex",
                    justifyContent: "end"
                }}
            >
                <img
                    src={FooterImage}
                    style={{ width: "40%", height: "30%" }}
                />
            </Box>
        </Container>
    </Box>
)

export default DeskopFooter