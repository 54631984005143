import React from 'react'
import { Box, MenuItem, Select, TextField, Typography } from '@mui/material'
import TagButton from '../../TagButton'
import PhoneNumberInput from '../../PhoneNumberInput'

const Form = () => {
    const [values, setValues] = React.useState({
        phone: '',
    })

    const onChangeValue = (key) => (value) => {
        setValues(current => {
            return {
                ...current,
                [key]: value,
            }
        })
    }

    return (
        <Box
            sx={{
                pl: { xs: 0, md: '4rem' },
                pt: { xs: 0, md: '5rem' }
            }}>
            <Box
                sx={{
                    backgroundColor: '#ffffff',
                    borderRadius: 2,
                    py: 4,
                    px: 3,
                    width: '100%'
                }}>
                <Typography color="primary" variant="subtitle">
                    Hablemos :)
                </Typography>
                <Typography sx={{ mb: '2rem' }}>
                    Si lo prefieres rellena este formulario
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        gap: 3,
                        width: '100%'
                    }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 1,
                            width: { xs: '100%', md: '50%' }
                        }}>
                        <Typography component="label">Nombre</Typography>
                        <TextField
                            name="name"
                            type="text"
                            placeholder="ej: John Doe"
                            size="small"
                            sx={{ backgroundColor: '#f5f5f5', mb: '.5rem' }}
                        />
                        <Typography component="label">Email</Typography>
                        <TextField
                            name="email"
                            type="email"
                            placeholder="ej: john@email.com"
                            size="small"
                            sx={{ backgroundColor: '#f5f5f5', mb: '.5rem' }}
                        />
                        <Typography component="label">Teléfono</Typography>
                        <PhoneNumberInput
                            onChange={onChangeValue('phone')}
                            placeholder="5432109876"
                        />
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 1,
                            width: { xs: '100%', md: '50%' }
                        }}>
                        <Typography component="label">Departamento</Typography>
                        <Select
                            name="name"
                            type="text"
                            label="Selecciona un departamento"
                            size="small"
                            sx={{ backgroundColor: '#f5f5f5', mb: '.5rem' }}>
                            <MenuItem value="ventas">Ventas</MenuItem>
                            <MenuItem value="marketing">Marketing</MenuItem>
                            <MenuItem value="guias">Guias</MenuItem>
                        </Select>
                        <Typography component="label">Mensaje</Typography>
                        <TextField
                            multiline
                            rows={5}
                            placeholder="Escribe tu mensaje"
                            size="small"
                            sx={{ backgroundColor: '#f5f5f5' }}
                        />
                    </Box>
                </Box>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    gap: { xs: 3, md: 5 },
                    mt: '1rem',
                    justifyContent: 'flex-end'
                }}>
                <Typography
                    color="primary.contrastText"
                    variant="body2"
                    fontWeight={400}></Typography>
                <TagButton
                    text="Enviar"
                    sx={{
                        width: { xs: '100%', md: '10rem' },
                        height: '2rem',
                        marginTop: '.5rem'
                    }}
                />
            </Box>
        </Box>
    )
}

export default Form
