import React from "react"
import { Box, Link, Typography } from "@mui/material"
import BlurImageWrapper, { orientations, opacities } from "../BlurImageWrapper"

const DestinationCard = ({
    title,
    imageUrl,
    url = '#',
    textPosition = "end"
}) => {
    if (!title) return null

    return (
        <Link href={url} underline="none" sx={{ maxWidth: "8rem" }}>
            <BlurImageWrapper
                imageUrl={imageUrl}
                orientation={orientations.bottomToTop}
                opacity={opacities.lighter}
                styles={{
                    borderRadius: "4%",
                }}
            >
                <Box sx={{
                    alignItems: textPosition,
                    display: "flex",
                    justifyContent: textPosition === "center" ? "center" : "left",
                    height: "18rem",
                    p: "1rem",
                    textAlign: textPosition === "center" ? "center" : "left",
                    width: "14.5rem"
                }}>
                    <Typography color="primary.contrastText" sx={{
                        fontFamily: "Inter",
                        fontSize: { xs: 18, md: 24 },
                        fontWeight: 700,
                    }}>
                        {title}
                    </Typography>
                </Box>
            </BlurImageWrapper>
        </Link>
    )
}

export default DestinationCard
