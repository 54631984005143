import React from "react"
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from "@mui/material"
import { ArrowDropDownIcon } from "@mui/x-date-pickers"
import IncrementalButtons from "../IncrementalButtons"


const RadioButtonsFilter = ({ title, options, onAdd, onRemove}) => (
    <Accordion sx={{ mb: '25px', borderRadius: '8px' }}>
        <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls={`${title}-content`}
            id={`${title}-header`}
        >
            <Typography>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
            {
                options.map(option => (
                    <Box
                        key={option.title}
                        sx={{
                            alignItems: "center",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            mb: "1rem",
                        }}
                    >
                        <Box>
                            <Typography sx={{
                                fontWeight: "500",
                            }}>
                                {option.title}
                            </Typography>
                            <Typography color="grayText" sx={{
                                fontWeight: "400",
                                fontSize: 12,
                                fontFamily: "Inter",
                            }}>
                                {option.subtitle}
                            </Typography>
                        </Box>
                        <IncrementalButtons
                            {...option}
                            onIncrement={onAdd}
                            onDecrement={onRemove}
                        />
                    </Box>
                ))
            }
        </AccordionDetails>
    </Accordion>
)

export default RadioButtonsFilter
