export const tourData = {
    id: "1",
    title: "Estambul nocturno: Sofía y Mezquita Azul",
    description: "Lorem ipsum dolor sit amet consectetur. Aliquet amet in proin nibh. Habitasse metus quis at est platea sem venenatis pellentesque. Commodo velit nisl diam magnis massa",
    price: "155",
    rate: "9.2",
    breadCrums: [
        { 
            name: "Destinos",
            path: `/destinos` 
        },
        { 
            name: "Turquía",
            path: `/destinos/turquia` 
        },
        { 
            name: "Estambul",
            path: `/destinos/turquia/estambul` 
        },
    ],
    images: [
        require("../../assets/images/granada.png"),
        require("../../assets/images/apalaches.png"),
        require("../../assets/images/fimmvorouhals.png"),
        require("../../assets/images/hawai-forest.jpg"),
        require("../../assets/images/airplane.png"),
        require("../../assets/images/rome.png"),
    ],
    freeCancelation: true,
    details: {
        description: "Lorem ipsum dolor sit amet consectetur. Placerat quisque elementum viverra quis pellentesque mi. Congue maecenas ut.",
        includes: "Lorem ipsum dolor sit amet consectetur. Placerat quisque elementum viverra quis pellentesque mi. Congue maecenas ut.",
        notIncludes: "Lorem ipsum dolor sit amet consectetur. Placerat quisque elementum viverra quis pellentesque mi. Congue maecenas ut.",
    },
    itinerary: [
        {
            title: "La mezquita azul",
            description: "Lorem ipsum dolor sit amet consectetur. Placerat quisque elementum viverra quis pellentesque mi. Congue maecenas ut.",
            duration: "1h y 30 minutos",
            added: true,
            category: "religion"
        },
        {
            title: "Restaurante Alibi, el mejor restaurante de Turquía",
            description: "Lorem ipsum dolor sit amet consectetur. Placerat quisque elementum viverra quis pellentesque mi. Congue maecenas ut.",
            duration: "1h y 30 minutos",
            added: false,
            category: "alimentos",
        },
        {
            title: "Teatro en Turquía",
            description: "Lorem ipsum dolor sit amet consectetur. Placerat quisque elementum viverra quis pellentesque mi. Congue maecenas ut.",
            duration: "1h y 30 minutos",
            added: false,
            category: "entretenimiento",
        }
    ],
    additionalInformation: [
        {
            title: "Lorem ipsum",
            description: "Lorem ipsum dolor sit amet consectetur. Placerat quisque elementum viverra quis pellentesque mi. Congue maecenas ut.",
        },
        {
            title: "Lorem ipsum 1",
            description: "Lorem ipsum dolor sit amet consectetur. Placerat quisque elementum viverra quis pellentesque mi. Congue maecenas ut.",
        },
        {
            title: "Lorem ipsum 2",
            description: "Lorem ipsum dolor sit amet consectetur. Placerat quisque elementum viverra quis pellentesque mi. Congue maecenas ut.",
        },
    ]
}

export const topActivities = [
    {
        name: "Santa Sofía",
        path: "/destinos/turquia/estambul/santa-sofia"
    },
    {
        name: "Palacio de Topkapi",
        path: "/destinos/turquia/estambul/palacio-topkapi"
    },
    {
        name: "Cisterna Basílica",
        path: "/destinos/turquia/estambul/cisterna-basilica"
    },
    {
        name: "Santa Sofía",
        path: "/destinos/turquia/estambul/santa-sofia"
    },
    {
        name: "Palacio de Topkapi",
        path: "/destinos/turquia/estambul/palacio-topkapi"
    },
    {
        name: "Cisterna Basílica",
        path: "/destinos/turquia/estambul/cisterna-basilica"
    },
    {
        name: "Santa Sofía",
        path: "/destinos/turquia/estambul/santa-sofia"
    },
    {
        name: "Palacio de Topkapi",
        path: "/destinos/turquia/estambul/palacio-topkapi"
    },
    {
        name: "Cisterna Basílica",
        path: "/destinos/turquia/estambul/cisterna-basilica"
    },
]

export const reviews = {
    count: "2",
    reviews: [
        {
            "id_tour": 1,
            "raiting": 4,
            "comment": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem quis quasi tempora ea inventore at magni eveniet mollitia vitae explicabo.",
            "client_name": "Alejandro Rivero",
            "country": "MX",
            "approved": 1,
            "confirmation": "Lorem ipsum dolor sit amet.",
            "date_created": "2024-07-22"
        },
        {
            "id_tour": 1,
            "raiting": 5,
            "comment": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem quis quasi tempora ea inventore at magni eveniet mollitia vitae explicabo.",
            "client_name": "Mario Gonzalez",
            "country": "MX",
            "approved": 1,
            "confirmation": "Lorem ipsum dolor sit amet.",
            "date_created": "2024-07-20"
        }
    ]
}
