import { Box } from "@mui/material"
import SocialLink, {socialVariants} from "../SocialLink";

const SocialLinksSection = () => {
    const socialLinks = Object.keys(socialVariants);
    return (
        <Box sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            my: "2rem",
        }}>
            {
                socialLinks.map((variant) => (
                    <SocialLink key={variant} variant={variant} />
                ))
            }
        </Box>
    )
}

export default SocialLinksSection;
