
import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { DateCalendar } from "@mui/x-date-pickers";
import dayjs from "dayjs";

const Calendar = ({ title, date, handleChange }) => (
    <Accordion defaultExpanded sx={{ mb: '25px', borderRadius: '8px' }}>
        <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls={`${title}-content`}
            id={`${title}-header`}
        >
            <Typography>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ height: '340px' }}>
            <DateCalendar minDate={dayjs(new Date())} sx={{ width: 'auto', height: 'auto' }} value={date} onChange={handleChange} />
        </AccordionDetails>
    </Accordion>
)

export default Calendar;
