import React from "react";

import { Box, IconButton, Typography } from "@mui/material";
import { ReactComponent as PlusCircle } from "../../assets/icons/plus-circle.svg";
import {ReactComponent as MinusCircle} from "../../assets/icons/minus-circle.svg";

const EditAssistants = ({label, parameters, value, clave, handleIncrement, handleDecrement}) => {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: "row",
            justifyContent: "space-between",
        }}>
            <Box>
                <Typography
                    variant="body2"
                    sx={{
                        fontSize: '13px',
                        fontWeight: 'bold'
                    }}
                >{label}</Typography>
                <Typography
                    variant="body2"
                >{parameters}</Typography>
            </Box>
            <Box sx={{
                display: 'flex',
                alignItems: 'center'
            }}>
                <IconButton
                    onClick={() => handleDecrement(clave)}
                    aria-label="decrement"
                >
                    <MinusCircle />
                </IconButton>
                <Typography
                    variant="body2"
                    component="p"
                    sx={{
                        fontSize: '16px',
                        px: '15px'
                    }}
                >{value}</Typography>
                <IconButton
                    aria-label="increment"
                    onClick={() => handleIncrement(clave)}
                >
                    <PlusCircle />
                </IconButton>
            </Box>
        </Box>
    )
}

export default EditAssistants;