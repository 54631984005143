import React, { useEffect, useState } from "react";

import { Accordion, AccordionDetails, AccordionSummary, AppBar, Box, Dialog, IconButton, Slide, Toolbar, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { ReactComponent as CheckCircle } from "../../assets/icons/check-circle.svg";
import { ReactComponent as UncheckCircle } from "../../assets/icons/uncheck-circle.svg";
import Step1 from "./step1";
import Step2 from "./step2";
import Step3 from "./step3";
import CardResumen from "./cardResumen";
import { getTotalAssistants } from "../../utils/checkout";




const UpdateTourDialog = ({ open, handleClose, tour }) => {
    const [persons, setPersons] = useState({ Adulto: 0, Infante: 0, Bebe: 0 });
    const [expandAccordion1, setExpandAccordion1] = useState(true);
    const [expandAccordion2, setExpandAccordion2] = useState(true);
    const [expandAccordion3, setExpandAccordion3] = useState(true);
    
    const {
        id,
        imageUrl,
        price,
        rate,
        reviews,
        title,
        description,
        languages,
        cancelable,
        time,
        date = '',
        type = '',
        assistants = '',
        moreDetails,
        edit,
        remove,
        optionals,
    } = tour;

    const { pax } = optionals;

    const handleExpandAccordion1 = () => setExpandAccordion1(!expandAccordion1)

    const handleExpandAccordion2 = () => setExpandAccordion2(!expandAccordion2)

    const handleExpandAccordion3 = () => setExpandAccordion3(!expandAccordion3)

    const handleDecrement = (clave) => {
        if (persons[clave] > 0) {
            setPersons({...persons, [clave]: persons[clave] - 1})
        }
    }

    const handleIncrement = (clave) => {
        setPersons({...persons, [clave]: persons[clave] + 1})
    }

    useEffect(() => {
        let personsAux = { Adulto: 0, Infante: 0, Bebe: 0 };

        pax.map(item => personsAux[item.name] = item.total);

        setPersons(personsAux);
    }, [pax])
    const image = require(`../../assets/${imageUrl}`)

    const handleSave = () => {

    }
    return (
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
        >
            <AppBar sx={{
                position: 'relative',
                bgcolor: '#FFFFFF',
                py: '40px',
                boxShadow: 'none'
            }}>
                <Toolbar>
                    <img
                    src={image}
                        style={{
                            width: "70px",
                            height: "50px",
                            borderRadius: '8px'
                        }}
                    />
                    <Typography sx={{
                        ml: 2,
                        flex: 1,
                        color: 'secondary.main',
                        fontSize: '18px'
                    }} variant="h6">
                        {title}
                    </Typography>
                    <IconButton
                        edge="start"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Box sx={{
                px: '20px'
            }}>
                <Box
                    onClick={handleExpandAccordion1}
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: 'center'
                    }}
                >
                    <Typography
                        variant="body2"
                        component="p"
                        sx={{
                            fontSize: '16px',
                            color: 'secondary.main'
                        }}
                    >
                        Paso 1 de 3 
                    </Typography>
                    {expandAccordion1 ? <CheckCircle /> : <UncheckCircle />}
                </Box>
                
                {expandAccordion1 && <Step1
                    assistants={getTotalAssistants(pax)}
                    date={date}
                    handleDecrement={handleDecrement}
                    handleIncrement={handleIncrement}
                    persons={persons}
                />}
                <Box
                    onClick={handleExpandAccordion2}
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: 'center',
                        mt: '20px'
                    }}
                >
                    <Typography
                        variant="body2"
                        component="p"
                        sx={{
                            fontSize: '16px',
                            color: 'secondary.main'
                        }}
                    >
                        Paso 2 de 3 
                    </Typography>
                    <CheckCircle />
                </Box>
                {expandAccordion2 && <Step2 />}
                <Box
                    onClick={handleExpandAccordion3}
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: 'center',
                        mt: '20px'
                    }}
                >
                    <Typography
                        variant="body2"
                        component="p"
                        sx={{
                            fontSize: '16px',
                            color: 'secondary.main'
                        }}
                    >
                        Paso 3 de 3 
                    </Typography>
                    <CheckCircle />
                </Box>
                {expandAccordion3 && <Step3 />}
            </Box>
            <Box>
                <CardResumen handleSave={handleSave}/>
            </Box>
        </Dialog>
    )
}

export default UpdateTourDialog;
