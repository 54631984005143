import React, { useEffect } from "react"
import { Box, Typography } from "@mui/material"
import TagButton from "../TagButton"

const PaymentSummary = ({
    items,
    iva = 0,
}) => {
    const total = items.reduce((a, b) => {
        return b.value * parseInt(b.price)
    }, 0)

    const [totalPrice, setTotalPrice] = React.useState(0)

    useEffect(() => {
        const total = items.reduce((total, item) => total + (item.price * item.value), 0);
        setTotalPrice(total)
    }, [items])

    return (
        <Box sx={{
            backgroundColor: '#F0F0F1',
            borderRadius: "5px",
            p: "1.2rem",
        }}>
            <Typography color="secondary" variant="h5" mb={1}>
                Resumen
            </Typography>
            {
                items.map((item, index) => (
                    <Box
                        key={`${item.title}${index}`}
                        sx={{
                            alignItems: "center",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            mb: ".5rem",
                        }}
                    >
                        <Typography
                            color="secondary"
                            sx={{
                                fontSize: 14,
                                fontFamily: "inter",
                            }}
                        >
                            {item.title}
                        </Typography>
                        <Typography
                            color="secondary"
                            sx={{
                                fontSize: 14,
                                fontFamily: "inter",
                            }}
                        >
                            {item.price * item.value} €
                        </Typography>
                    </Box>
                ))
            }
            <Box sx={{
                alignItems: "center",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                mt: "1.5rem",
            }}
            >
                <Typography color="secondary" sx={{ lineHeight: 1 }}>
                    Total:
                </Typography>
                <Typography color="secondary" variant="h5" sx={{ lineHeight: 1 }}>
                    {totalPrice}€
                </Typography>
            </Box>
            <Typography
                color="grayText"
                sx={{
                    fontSize: 12,
                    fontFamily: "inter",
                    textAlign: "right",
                    mb: "1.5rem",
                }}
            >
                {totalPrice * iva / 100}€ IVA
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
                <TagButton
                    text="Seguir personalizando mi reserva"
                    onClick={() => { }}
                    id='personalizar-btn'
                    sx={{
                        borderRadius: "2rem",
                        px: "1.5rem",
                        fontSize: 16,
                        fontFamily: 'Poppins',
                        fontWeight: 600,
                        lineHeight: 1.5,
                        mb: "1rem",
                        py: { xs: "1rem", md: "3px" },
                    }}
                />
            </Box>
        </Box>
    )
}

export default PaymentSummary
