import MobileFooter from "./MobileFooter"
import DeskopFooter from "./DesktopFooter"
import { useScreenDetector } from "../../hooks/useScreenDetector"

const Footer = () => {
    const {isMobile} = useScreenDetector()
    return isMobile ? <MobileFooter /> : <DeskopFooter />
}

export default Footer
