import React, { useEffect, useState } from "react";

import { Box, IconButton, MenuItem, Select, Typography } from "@mui/material";
import { ReactComponent as Users } from '../../assets/icons/users.svg'
import { ReactComponent as Edit } from '../../assets/icons/edit-3.svg'
import { ReactComponent as Calendar } from '../../assets/icons/calendar.svg'
import dayjs from "dayjs";
import EditAssistants from "./editAssistants";
import { DateCalendar } from "@mui/x-date-pickers";

const CmLabel = (props) => {
    const { children, ...lProps } = props;
    return (
        <label style={{color: '#6D7280', fontSize: '14px'}} {...lProps}>{children}</label>
    );
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const ASSISTANTS_LABELS = [
    {
        clave: 'Adulto',
        label: 'Adultos',
        parameters: '(+12 años)'
    },
    {
        clave: 'Infante',
        label: 'Niños',
        parameters: '(2 - 11 años)'
    },
    {
        clave: 'Bebe',
        label: 'Bebé',
        parameters: '(0 - 2 años)'
    }
]

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const assistantsOptions = [
    'Familia',
    'Amigos',
];

const languages = [
    'Ingles',
    'Espanol'
]

const Step1 = ({assistants, date, handleDecrement, handleIncrement, persons}) => {
    const [assistant, setAssistant] = useState('Familia')
    const [language, setLanguage] = useState('Ingles')

    const [showEditAssistants, setShowEditAssistants] = useState(false);
    const [showEditDate, setShowEditDate] = useState(false);

    const handleShowEditDate = () => {
        if (showEditAssistants == true) {
            setShowEditAssistants(false)
        }
        setShowEditDate(!showEditDate);
    }
    const handleShowEditAssistants = () => {
        if (showEditDate == true) {
            setShowEditDate(false)
        }

        setShowEditAssistants(!showEditAssistants);
    }

    const handleChangeAssitants = (event) => {
        setAssistant(event.target.value);
    }

    const handleChangeLanguages = (event) => {
        setLanguage(event.target.value);
    }

    const handleChangeCalendar = (newDate) => {
        console.log(newDate)
    }

    

    return (
        <>
            <Typography
                variant="h5"
                sx={{
                    fontSize: '22px',
                    color: 'secondary.main',
                    lineHeight: 1
                }}
            >
                Tu Tour
            </Typography>
            <Box sx={{
                display: 'flex',
                my: '20px'
            }}>
                <Box sx={{
                    display: "flex",
                    alignItems: 'center',
                    py: '5px',
                    px: '10px',
                    borderRadius: '16px',
                    border: '1px solid #40C5E8'
                }}>
                    <Box sx={{
                        display: 'flex',
                    }}>
                        <Users width={14} height={14}/>
                        <Box sx={{
                            px: '5px'
                        }}>
                            <Typography sx={{
                                fontSize: '10px',
                                color: 'secondary.main',
                                fontWeight: 500
                            }}>
                                {assistants} Personas
                            </Typography>
                        </Box>
                    </Box>
                    <IconButton
                        aria-label="edit assitants"
                        onClick={handleShowEditAssistants}
                    >
                        <Edit width={14} height={14}/>
                    </IconButton>
                </Box>
                <Box sx={{
                    display: "flex",
                    alignItems: 'center',
                    py: '5px',
                    ml: '10px',
                    px: '10px',
                    borderRadius: '16px',
                    border: '1px solid #40C5E8'
                }}>
                    <Box sx={{
                        display: 'flex'
                    }}>
                        <Calendar width={14} height={14}/>
                        <Box sx={{
                            px: '5px'
                        }}>
                            <Typography sx={{
                                fontSize: '10px',
                                color: 'secondary.main',
                                fontWeight: 500
                            }}>
                                {dayjs(date).format('MMMM D, YYYY')}
                            </Typography>
                        </Box>
                    </Box>
                    <IconButton
                        aria-label="edit date"
                        onClick={handleShowEditDate}
                    >
                        <Edit width={14} height={14}/>
                    </IconButton>
                </Box>
            </Box>
            {showEditAssistants && <Box sx={{
                px: '20px'
            }}>
                {ASSISTANTS_LABELS.map(item => (
                    <EditAssistants
                        key={item.clave}
                        label={item.label}
                        parameters={item.parameters}
                        handleDecrement={handleDecrement}
                        handleIncrement={handleIncrement}
                        value={persons[item.clave]}
                        clave={item.clave}
                    />
                ))}
            </Box>}
            {showEditDate && <Box>
                <DateCalendar
                    sx={{
                        width: 'auto',
                        height: 'auto'
                    }}
                    value={dayjs(date)}
                    onChange={(newValue) => handleChangeCalendar(newValue)}
                />
            </Box>}
            <Box
                sx={{
                    display: 'grid',
                    width: '100%',
                    py: '5px'
                }}
            >
                <CmLabel htmlFor="assistant">¿Con quien vas?</CmLabel>
                <Select
                    labelId="assistant-id"
                    name="assistant"
                    value={assistant}
                    onChange={handleChangeAssitants}
                    MenuProps={MenuProps}
                    sx={{
                        fontSize: '13px',
                        height: '40px',
                        borderRadius: '8px'
                    }}
                    >
                    {assistantsOptions.map((item) => (
                        <MenuItem
                            key={item}
                            value={item}
                            >
                            {item}
                        </MenuItem>
                    ))}
                </Select>
            </Box>

            <Box
                sx={{
                    display: 'grid',
                    width: '100%',
                    py: '5px'
                }}
            >
                <CmLabel htmlFor="language">¿En que idioma quieres el tour?</CmLabel>
                <Select
                    labelId="language-id"
                    name="language"
                    value={language}
                    onChange={handleChangeLanguages}
                    MenuProps={MenuProps}
                    sx={{
                        fontSize: '13px',
                        height: '40px',
                        borderRadius: '8px'
                    }}
                    >
                    {languages.map((item) => (
                        <MenuItem
                            key={item}
                            value={item}
                            >
                            {item}
                        </MenuItem>
                    ))}
                </Select>
            </Box>
        </>
    )
}

export default Step1;