import React from 'react'
import axios from 'axios'

const useFetchDestinationsFeatured = () => {
    const [destinationsFeatured, setDestinationsFeatured] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);

    const fetchDestinationsFeatured = React.useCallback(async () => {
        setIsLoading(true)
        const { REACT_APP_BASE_URL } = process.env
        const token = localStorage.getItem('token');
        
        try {
            const { data } = await axios.get(
                `${REACT_APP_BASE_URL}/index.php?r=api/tours/destinationsfeatured`,
                {
                    headers: {
                        'x-authorization-token': token
                    }
                }
            ) || {}

            setIsLoading(false)
            if (data.length) {
                setDestinationsFeatured(data)
            }
        } catch (err) {
            console.error('Error fetching destinations featured: ', err)
            setIsLoading(false)
        }
    }, [])

    React.useEffect(() => {
        fetchDestinationsFeatured()
    }, [fetchDestinationsFeatured])

    return {
        destinationsFeatured,
        isLoading,
    }
}

export default useFetchDestinationsFeatured
