import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import { pages } from '../../utils/data'
import trekzyLogo from '../../assets/images/trekzy-logo.svg'

const MainLayout = ({children}) => {
    return (
        <>
            <Header logoImgSource={trekzyLogo} pages={pages} />
            { children }
            <Footer />
        </>
    )
}

export default MainLayout;