import trekzyLogo from '../../assets/images/trekzy-logo2.svg'
import Header2 from '../Header2'
import Footer2 from '../Footer2'

const CheckoutLayout = ({ children }) => {
    return (
        <>
            <Header2 logoImgSource={trekzyLogo} />
            {children}
            <Footer2 />
        </>
    )
}

export default CheckoutLayout
