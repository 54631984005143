import React from 'react'
import axios from 'axios'

const useFetchCities = ({ country }) => {
    const [cities, setCities] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);

    const fetchCityList = React.useCallback(async () => {
        setIsLoading(true)
        const { REACT_APP_BASE_URL } = process.env
        const token = localStorage.getItem('token');

        try {
            const { data } = await axios.get(
                `${REACT_APP_BASE_URL}/index.php?r=api%2Fdestinations%2Fcountry&country=${country}`,
                {
                    headers: {
                        'x-authorization-token': token
                    }
                }
            ) || {}

            if (data.cities.length) {
                setCities(data)
            }
            setIsLoading(false)
        } catch (err) {
            console.error('Error fetching destinations featured: ', err)
            setIsLoading(false)
        }
    }, [])

    React.useEffect(() => {
        fetchCityList()
    }, [fetchCityList])

    return {
        cities,
        isLoading,
    }
}

export default useFetchCities
