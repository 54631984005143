import React from "react";

import {
    Grid,
    Input,
    InputAdornment,
    MenuItem,
    Select,
    TextField,
} from "@mui/material";
import { ReactComponent as CreditCard } from "../../../assets/icons/credit-card.svg";
import './styles.css'

const CmLabel = (props) => {
    const { children, ...lProps } = props;
    return (
        <label style={{color: '#6D7280', fontSize: '14px'}} {...lProps}>{children}</label>
    );
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const cards = [
  '0000 0000 0000 0000',
  '1111 1111 1111 1111',
  '2222 2222 2222 2222',
];

const FormPago = ({ formData, handleChange }) => {
    const [card, setCard] = React.useState([]);
    const handleChangeCard = (event) => {
        const {
        target: { value },
        } = event;
        setCard(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
        );
    };

    return (
        <form>
            <Grid container spacing={3}
                sx={{
                    '& .MuiInputBase-input': {
                        p: '7px 10px',
                        bgcolor: '#F9FAFB', 
                        border: '1px solid #F9FAFB'
                    }
                }}
            >
                <Grid item xs={12} md={8}
                    sx={{display: 'grid'}}
                >
                    <Select
                        labelId="type-card"
                        name="typeCard"
                        value={card}
                        onChange={handleChangeCard}
                        MenuProps={MenuProps}
                        sx={{
                            bgcolor: '#F9FAFB',
                        }}
                        startAdornment={
                            <InputAdornment position="start">
                                <CreditCard />
                            </InputAdornment>
                        }
                        >
                        {cards.map((card) => (
                            <MenuItem
                                key={card}
                                value={card}
                                >
                                {card}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>
                <Grid item xs={12} md={6}
                    sx={{display: 'grid'}}
                >
                    <CmLabel htmlFor="numCard">Detalles de tarjeta</CmLabel>
                    <Input
                        startAdornment={
                            <InputAdornment position="start">
                                <CreditCard height={15} width={15}/>
                            </InputAdornment>
                        }
                        sx={{
                            border: '1px solid rgb(192, 192, 192)',
                            borderRadius: '4px',
                            bgcolor: '#F9FAFB',
                            "::before": {
                                borderBottom: 'none!important'
                            },
                            "::after": {
                                borderBottom: 'none'
                            },
                            '& .MuiInputAdornment-root': {
                                pl: '5px',
                                mr: 0,
                            },
                            '& .MuiInputBase-input': {
                                pl: '5px'
                            }
                        }}
                        name="numCard"
                        type="text"
                        placeholder="0000 00000 0000 0000 00"
                        value={formData.numCard}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12} md={3}
                    sx={{display: 'grid'}}
                >
                    <CmLabel htmlFor="name">Fecha vencimiento</CmLabel>
                    <TextField
                        name="date"
                        type="text"
                        placeholder="MM/YY"
                        value={formData.date}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12} md={3}
                    sx={{display: 'grid'}}
                >
                    <CmLabel htmlFor="name">CVV</CmLabel>
                    <TextField
                        name="cvv"
                        type="text"
                        placeholder="CVV"
                        value={formData.cvv}
                        onChange={handleChange}
                    />
                </Grid>

                <Grid item xs={12} md={6}
                    sx={{display: 'grid'}}
                >
                    <CmLabel htmlFor="name">Nombre*</CmLabel>
                    <TextField
                        name="name"
                        type="text"
                        placeholder="Introduce..."
                        value={formData.name}
                        onChange={handleChange}
                    />
                </Grid>

                <Grid item xs={12} md={6}
                    sx={{display: 'grid'}}
                >
                    <CmLabel htmlFor="lastName">Apellidos*</CmLabel>
                    <TextField
                        name="lastName"
                        type="text"
                        placeholder="Introduce..."
                        value={formData.lastName}
                        onChange={handleChange}
                    />
                </Grid>

                <Grid item xs={12} md={6}
                    sx={{display: 'grid'}}
                >
                    <CmLabel htmlFor="address">Direccion de facturacion*</CmLabel>
                    <TextField
                        name="address"
                        type="text"
                        placeholder="Introduce..."
                        value={formData.address}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12} md={6}
                    sx={{display: 'grid'}}
                >
                    <CmLabel htmlFor="country">Pais*</CmLabel>
                    <TextField
                        name="country"
                        type="text"
                        placeholder="Introduce..."
                        value={formData.country}
                        onChange={handleChange}
                    />
                </Grid>
            </Grid>
        </form>
    )
}

export default FormPago;