import React from 'react'
import { Box, Link, Typography } from '@mui/material'
import MainLayout from '../../components/MainLayout'
import StaticPagesWrapper from '../../components/StaticPagesWrapper'
import ListItemHeader from '../../components/StaticPagesWrapper/ListItemHeader'

const PrivacyPolicies = () => (
    <MainLayout>
        <StaticPagesWrapper title='Trekzy'>
            <Box mb={8}>
                <ListItemHeader styles={{ padding: 0 }}>
                    Política de privacidad
                </ListItemHeader>
                <Typography mb={2}>
                    Siguiendo los principios de licitud, lealtad y transparencia, ponemos a su disposición la
                    presente Política de Privacidad.
                </Typography>
                <ListItemHeader styles={{ padding: 0 }}>
                    ¿Quién es el responsable del tratamiento de sus datos personales?
                </ListItemHeader>
                <ListItemHeader styles={{ padding: 0 }}>
                    Identidad y datos del responsable
                </ListItemHeader>
                <Typography mb={2}>
                    Tour Review, S.L. (en lo sucesivo, “TREKZY”)<br/>
                    CIF: B56820913<br/>
                    DOMICILIO SOCIAL: Pasaje de Bisbal 6 1ª Planta, 28028 Madrid España<br/>
                    CONTACTO: juana@tourreview.com<br/>
                    TELÉFONO: (+34) 91 75 78 557<br/>
                </Typography>
                <ListItemHeader styles={{ padding: 0 }}>
                    ¿Con qué finalidad tratamos sus datos personales?
                </ListItemHeader>
                <Typography mb={2}>
                    Esta Política de Privacidad tiene por objeto brindar información sobre el tratamiento de
                    los datos personales (en lo sucesivo, los <strong>“Datos Personales”</strong>) de aquellas personas
                    físicas o morales que contratan los servicios de TREKZY que ofrece a través de los sitios
                    web de su propiedad: www.trekzy.com y www.toursprivados.com (el <strong>“Sitio Web”</strong>) y/o que
                    solicitan información sobre los términos y condiciones de nuestros servicios y/o que
                    tienen relación comercial, profesional y/o laboral con TREKZY.<br/><br/>
                    Esta Política de Privacidad resulta aplicable a la recogida de Datos Personales efectuada
                    a través de nuestro Sitio Web o cualquier otro medio y al tratamiento de dicha información
                    cuando ha sido obtenida por TREKZY a través de otras fuentes legalmente reconocidas.<br/><br/>
                    En TREKZY, dependiendo de la categoría de interesado de que se trate, tratamos la
                    información que nos facilita con las siguientes finalidades
                </Typography>
                <ListItemHeader styles={{ padding: 0 }}>
                    ¿Qué datos personales recabamos?
                </ListItemHeader>
                <Typography mb={2}>
                    Para el cumplimiento de las finalidades anteriormente relacionadas, TREKZY según el
                    tipo de interesado podrá recoger y tratar las siguientes categorías de Datos Personales:<br/><br/>
                    CLIENTES/POTENCIALES CLIENTES/USUARIOS DEL SITIO WEB:
                    <ul>
                        <li>
                            Nombre y apellidos (y de sus acompañantes)
                        </li>
                        <li>
                            Número de documento nacional de identidad (en ciertos casos y en caso de que
                            los servicios lo requieran)
                        </li>
                        <li>
                            Correo electrónico
                        </li>
                        <li>
                            Datos bancarios
                        </li>
                        <li>
                            Información de contacto
                        </li>
                        <li>
                            Datos relativos a la salud, cuando el interesado los proporciona de manera
                            expresa y voluntaria para obtener una prestación de servicios que requiere tomar
                            en cuenta, precisamente, el estado de salud de dicho interesado o de uno o más
                            de sus acompañantes.
                        </li>
                        <li>
                            Dirección de protocolo de internet (IP)
                        </li>
                    </ul>
                    TREKZY no trata Datos Personales para elaborar perfiles de sus clientes o prospectos,
                    ni utiliza tecnologías para la adopción de decisiones individuales automatizadas.<br /><br />
                    EMPLEADOS Y CANDIDATOS:<br /><br />
                    Con el fin de (i) gestionar, controlar y administrar los currículums de candidatos a puestos
                    de trabajo bajo demanda o espontaneamente por interesados; (ii) generar perfiles de
                    candidatos para entrevistas de trabajo; y/o (iii) ofrecer términos y condiciones de
                    contratación a empleados y candidatos dentro de los procesos de selección
                    desarrollados por TREKZY y conforme a sus politicas de contratación, se podrán recoger,
                    entre otros, los siguientes Datos Personales:
                    <ul>
                        <li>
                            Nombre y apellidos
                        </li>
                        <li>
                            Número de documento nacional de identidad
                        </li>
                        <li>
                            Correo electrónico
                        </li>
                        <li>
                            Género
                        </li>
                        <li>
                            Domicilio
                        </li>
                        <li>
                            Datos bancarios
                        </li>
                        <li>
                            Información de contacto
                        </li>
                        <li>
                            Datos relativos a la salud
                        </li>
                        <li>
                            Datos academicos y/o profesionales
                        </li>
                        <li>
                            Datos economicos, financieros y de seguros
                        </li>
                        <li>
                            Certificado de antecedentes penales
                        </li>
                    </ul>
                    PROVEEDORES:<br/><br/>
                    Con el fin de que TREKZY este en posibilidad de garantizar la calidad de los servicios a
                    sus clientes y gestionar y administrar la relación comercial con los proveedores de
                    TREKZY podremos recoger los siguientes Datos Personales:
                    <ul>
                        <li>
                            Nombre y apellidos en caso de ser persona fisica y documentación de legal
                        </li>
                        <li>
                            existencia en caso de ser persona jurídica
                        </li>
                        <li>
                            Número de documento nacional de identidad
                        </li>
                        <li>
                            Correo electrónico
                        </li>
                        <li>
                            Género; en caso de ser persona física
                        </li>
                        <li>
                            Domicilio
                        </li>
                        <li>
                            Datos bancarios
                        </li>
                        <li>
                            Información de contacto
                        </li>
                        <li>
                            Certificado de antecedentes penales
                        </li>
                    </ul>
                </Typography>
                <ListItemHeader styles={{ padding: 0 }}>
                    Datos personales de menores de edad
                </ListItemHeader>
                <Typography mb={2}>
                    TREKZY no recaba Datos Personales de menores de edad de manera directa ni
                    personal. Nuestros servicios están dirigidos a mayores de edad, quienes podrán facilitar
                    los datos personales de menores de edad que los acompañen y sobre los cuales ejerzan
                    la patria potestad o la tutela correspondiente.
                </Typography>
                <ListItemHeader styles={{ padding: 0 }}>
                    Interesados; Finalidades del tratamiento
                </ListItemHeader>
                <Typography mb={2}>
                    POTENCIALES CLIENTES: Gestionar la potencial relación comercial y/o profesional,
                    gestionar el envío de información solicitada y/o resolver las consultas planteadas, facilitar
                    ofertas de nuestros servicios y/o productos de su interés.<br/><br/>
                    CLIENTES: Gestionar la relación comercial y/o profesional, facilitar ofertas de nuestros
                    servicios y/o productos de su interés.<br/><br/>
                    Para la prestación de los servicios contratados, debemos comunicar sus Datos
                    Personales con el fin de asegurar que estos se proporcionen en las fechas y para las
                    personas que los han contratado a través de TREKZY y/o del Sitio Web. Si Usted decide
                    que no desea comunicar sus Datos Personales y/o el de sus acompañantes, es posible
                    que no podamos prestarle el servicio o garantizar su disponibilidad.<br/><br/>
                    PROVEEDORES: Gestionar la relación comercial y/o profesional con TREKZY.<br/><br/>
                    EMPLEADOS Y CANDIDATOS: Gestionar el proceso de selección y contratación de
                    personal con TREKZY; gestionar y administrar la relación laboral con TREKZY; gestionar
                    y administrar los expedientes laborales de los empleados y colaboradores de TREKZY,
                    incluyendo el pago de nómina; y gestionar, controlar y administrar medidas para el
                    cumplimiento de obligaciones laborales incluyendo las relacionadas con prevención de
                    riesgos laborales, incluyendo, en su caso, medicina preventiva o laboral.<br/><br/>
                    USUARIOS DEL SITIO WEB: Gestionar el análisis de sus datos de navegación en el
                    Sitio Web.
                </Typography>
                <ListItemHeader styles={{ padding: 0 }}>
                    ¿Cuál es la legitimación para el  tratamiento de sus datos personales?
                </ListItemHeader>
                <Typography mb={2}>
                    La base legal para el tratamiento de sus Datos Personales, dependiendo de la categoría
                    de interesado de que se trate, puede ser:
                </Typography>
                <ListItemHeader styles={{ padding: 0 }}>
                    Interesados base legal
                </ListItemHeader>
                <Typography mb={2}>
                    POTENCIALES CLIENTES:<br/><br/>
                    Art. 6.1.b RGPD: aplicación, a petición del interesado, de medidas precontractuales
                    (cotizaciones, presupuestos, hojas de encargo, ofertas de servicios…).<br/><br/>
                    Art. 6.1.f RGPD: Interés legítimo (enviar información solicitada, responder a las consultas
                    planteadas…).<br/><br/>
                    Art. 6.1.a RGPD: consentimiento del propio interesado (facilitar ofertas de nuestros
                    servicios y/o productos).<br/><br/>
                    CLIENTES:<br/><br/>
                    Art. 6.1.b RGPD: ejecución de un contrato de servicios en el que el interesado es parte.<br/><br/>
                    PROVEEDORES:<br/><br/>
                    Art. 6.1.b RGPD: ejecución de un contrato en el que el interesado es parte.<br/><br/>
                    EMPLEADOS Y CANDIDATOS:<br/><br/>
                    Art. 6.1.a RGPD: consentimiento del propio interesado.<br/><br/>
                    USUARIOS DEL SITIO WEB:<br/><br/>
                    Art. 6.1.a RGPD: consentimiento del propio interesado.<br/><br/>
                    Los Datos Personales que le solicitamos son adecuados, pertinentes y estrictamente
                    necesarios y en ningún caso está obligado a facilitárnoslos, pero su no comunicación (i)
                    podrá afectar a la finalidad del servicio o la imposibilidad de prestarlo si Usted es cliente
                    de TREKZY; (ii) impedirá la ejecución de un contrato de servicios si usted es proveedor;
                    o (iii) impedira la continuación de un proceso de contratación si usted es un candidato.
                </Typography>
                <ListItemHeader styles={{ padding: 0 }}>
                    ¿Por cuánto tiempo conservaremos sus datos personales?
                </ListItemHeader>
                <Typography mb={2}>
                    Sus Datos Personales, serán conservados el tiempo mínimo necesario para la correcta
                    prestación del servicio ofrecido, así como para atender las responsabilidades que se
                    pudieran derivar del mismo y de cualquier otra exigencia legal y de manera indefinida
                    para el envío de ofertas de nuestros servicios y otras comunicaciones.
                </Typography>
                <ListItemHeader styles={{ padding: 0 }}>
                    ¿A qué destinatarios se comunicarán sus datos?
                </ListItemHeader>
                <Typography mb={2}>
                    TREKZY, no comunicará ni transmitirá sus Datos Personales a ningún tercero, salvo que
                    se informe de ello expresamente.<br/><br/>
                    Adicionalmente le informamos que determinados Datos Personales, en virtud de la
                    normativa vigente o de la relación contractual que mantenga con TREKZY, podrán ser
                    comunicados a:
                    <ul>
                        <li>
                            Agencias de Viajes con las que tenemos relación contractual para ofrecerle
                            nuestros servicios.
                        </li>
                        <li>
                            Proveedores de transporte con los que tenemos relación contractual para
                            ofrecerle nuestros servicios.
                        </li>
                        <li>
                            Personas físicas y personas jurídicas que prestan servicios turísticos bajo
                            demanda y coordinación de TREKZY.
                        </li>
                        <li>
                            Establecimientos o destinos turísticos que requieren de manera expresa la
                            identidad de usuarios o visitantes.
                        </li>
                        <li>
                            Los bancos y entidades financieras para el cobro de los servicios contratados y/o
                            productos comprados.
                        </li>
                        <li>
                            Administraciones públicas con competencia en los sectores de la actividad de
                            TREKZY, cuando así lo establezca la normativa vigente.
                        </li>
                        <li>
                            Entidades afiliadas o subsidiarias de TREKZY dentro o fuera de la Unión Europea.
                        </li>
                    </ul>
                </Typography>
                <ListItemHeader styles={{ padding: 0 }}>
                    ¿Cuáles son sus derechos cuand nos facilita sus datos?
                </ListItemHeader>
                <Typography mb={2}>
                    Los derechos de protección de Datos Personales que podrán ejercer los interesados,
                    cuando procedan, son:
                    <ul>
                        <li>
                            Derecho a solicitar el acceso a los Datos Personales relativos al interesado.
                        </li>
                        <li>
                            Derecho de rectificación o supresión.
                        </li>
                        <li>
                            Derecho de oposición.
                        </li>
                        <li>
                            Derecho a solicitar la limitación de su tratamiento.
                        </li>
                        <li>
                            Derecho a la portabilidad de los Datos PErsonales.
                        </li>
                        <li>
                            Derecho a no ser objeto de decisiones basadas únicamente en el tratamiento
                            automatizado de Datos Personales.
                        </li>
                    </ul>
                    Los titulares de los Datos Personales obtenidos, podrán ejercer sus derechos de
                    protección de datos personales dirigiendo una comunicación por escrito al domicilio
                    social de TREKZY, o al correo electrónico habilitado a tal
                    efecto <Link href='mailto:datospersonales@amigotours.com.es'>datospersonales@amigotours.com.es</Link>.
                    Modelos, formularios y más información disponible sobre sus derechos en la página web
                    de la autoridad de control nacional, Agencia Española de Protección de Datos, en
                    adelante, AEPD, <Link href='www.aepd.es' target="_blank">www.aepd.es.</Link><br/><br/>
                    TREKZY dará respuesta dentro del plazo de un mes a partir de la recepción de su
                    solicitud y, en casos excepcionales, dicho plazo podrá prorrogarse hasta por otros dos.
                </Typography>
                <ListItemHeader styles={{ padding: 0 }}>
                    ¿Puedo retirar mi consentimiento?
                </ListItemHeader>
                <Typography mb={2}>
                    Usted tiene la posibilidad y el derecho a retirar el consentimiento para cualquier finalidad
                    específica otorgada en su momento, sin que ello afecte a la licitud del tratamiento basado
                    en el consentimiento previo a su retirada.
                </Typography>
                <ListItemHeader styles={{ padding: 0 }}>
                    ¿Dónde puedo reclamar en caso de que considere que no se tratan mis datos personales correctamente?
                </ListItemHeader>
                <Typography mb={2}>
                    Si algún interesado considera que sus Datos Personales no son tratados correctamente
                    por TREKZY, o que las solicitudes de ejercicio de derechos no han sido atendidas de
                    forma satisfactoria, puede interponer una reclamación ante a la autoridad de protección
                    de datos que corresponda, siendo la AEPD la indicada en el territorio nacional, <Link href='www.aepd.es' target="_blank">www.aepd.es.</Link>
                </Typography>
                <ListItemHeader styles={{ padding: 0 }}>
                    Seguridad y actualización de sus datos personales
                </ListItemHeader>
                <Typography mb={2}>
                    Con el objetivo de salvaguardar la seguridad de sus Datos Personales, le informamos
                    que TREKZY ha adoptado todas las medidas de índole técnica y organizativa necesarias
                    para garantizar la seguridad de los Datos Personales suministrados. Todo ello para evitar
                    su alteración, pérdida, y/o tratamientos o accesos no autorizados, tal como exige la
                    normativa, si bien la seguridad absoluta no existe.<br/><br/>
                    Es importante que, para que podamos mantener sus Datos Personales actualizados, nos
                    informe siempre que se produzca una modificación de los mismos.
                </Typography>
                <ListItemHeader styles={{ padding: 0 }}>
                    Transferencias internacionales
                </ListItemHeader>
                <Typography mb={2}>
                    CLIENTES/POTENCIALES CLIENTES/USUARIOS DEL SITIO WEB:<br/><br/>
                    Para las finalidades indicadas en la presente Política de Privacidad, TREKZY no realiza
                    transferencia de Datos Personales fuera del territorio de la Unión Europea.<br/><br/>
                    PROVEEDORES/CANDIDATOS/EMPLEADOS:<br/><br/>
                    Para las finalidades de gestión y administración indicadas en la presente Política de
                    Privacidad, TREKZY realiza transferencias de sus datos personales fuera del territorio
                    de la Unión Europea, con destino hacia los Estados Unidos Mexicanos.<br/><br/>
                    En virtud de que el destino de la transferencia no cuenta con una decisión de adecuación
                    por parte de la Comisión Europea, TREKZY ha adoptado con el destinario de los datos
                    personales Amigo Group Europa, S.L. medidas para garantizar un nivel adecuado de
                    protección a esa información, consistentes en la celebración entre las partes de cláusulas
                    contractuales tipo de protección de datos adoptadas por la Comisión Europea. Usted
                    puede obtener una copia de la versión pública de estas garantías adecuadas dirigiendo
                    una solicitud a <Link href='mailto:dataprotection@amigogroup.com.mx' target="_blank">dataprotection@amigogroup.com.mx</Link>.
                </Typography>
                <ListItemHeader styles={{ padding: 0 }}>
                    Confidencialidad
                </ListItemHeader>
                <Typography mb={2}>
                    TREKZY le informa que sus Datos Personales serán tratados con el máximo celo y
                    confidencialidad por todo el personal que intervenga en cualquiera de las fases del
                    tratamiento. No cederemos ni comunicaremos a ningún tercero sus datos, excepto en los
                    casos legalmente previstos, o salvo que el interesado nos hubiera autorizado
                    expresamente.
                </Typography>
                <ListItemHeader styles={{ padding: 0 }}>
                    Modificaciones y actualizaciones de la presente política de privacidad
                </ListItemHeader>
                <Typography mb={2}>
                    TREKZY podrá modificar, ampliar, corregir o actualizar la presente Política de Privacidad,
                    en cualquier momento y bajo su completa responsabilidad. En todos los casos,
                    publicaremos en el Sitio Web la fecha de última actualización de esta Política de
                    Privacidad.<br/><br/>
                    Podremos comunicar estos cambios a través del Sitio o mediante el envío de un correo
                    electrónico en caso de contar con su dirección pero sin estar obligado a ello.
                </Typography>
            </Box>
        </StaticPagesWrapper>
    </MainLayout>
)

export default PrivacyPolicies
