export const getHrefFromGuide = (guide) => {
    const sanitizedCity = guide?.city
        ?.replace(' ', '-')
        ?.normalize("NFD")
        .replace(/[\u0300-\u036f]/g, '')
        ?.toLowerCase()
    return `/guias/${sanitizedCity}/${guide.slug}`
}

export const getTourPath = (tour) => { 
    if (tour.type === undefined) return getHrefFromGuide(tour);
     const sanitizedCity = tour?.city
        ?.replace(' ', '-')
        ?.normalize("NFD")
        .replace(/[\u0300-\u036f]/g, '')
        ?.toLowerCase()
    return `/tour/${sanitizedCity}/${tour.slug}`
}