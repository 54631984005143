import { Box, Card, CardContent, CardMedia, Typography } from '@mui/material'

const SingleProductCard = ({ image, title, description }) => {
    const imageUrl = require(`../../../assets/images/${image}`)

    return (
        <Card
            sx={{
                maxWidth: 550,
                height: { xs: 360, md: 450 },
                boxShadow: 10,
                borderRadius: 2
            }}>
            <CardMedia
                sx={{ height: { xs: 180, md: 240 } }}
                image={imageUrl}
                title={title}
            />

            <CardContent>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        py: '.25rem',
                        px: 0
                    }}>
                    <Typography variant="subtitle1" sx={{ mb: '1rem' }}>
                        {title}
                    </Typography>
                    <Typography variant="body">{description}</Typography>
                </Box>
            </CardContent>
        </Card>
    )
}

export default SingleProductCard
