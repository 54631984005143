import React from 'react'
import {
    Box,
    Container,
    Divider,
    Link,
    List,
    ListItem,
    Typography
} from '@mui/material'
import PayMethodsSection from './PayMethodsSection'
import SocialLinksSection from './SocialLinksSection'
import FooterImage from '../../assets/images/trekzy-footer.png'
import { ReactComponent as TrekzyLogo } from '../../assets/images/trekzy-logo.svg'

const DeskopFooter = () => (
    <Box
        sx={{
            backgroundColor: '#F0F0F1',
            width: '100%'
        }}>
        <Container
            sx={{
                display: 'flex',
                alignItems: 'center',
                pt: '6rem',
                pb: '2rem',
                position: 'relative',
            }}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    paddingRight: '35%',
                }}>
                <TrekzyLogo
                    style={{ height: 40, width: 125, marginBottom: '.8rem' }}
                />
                <Divider
                    aria-hidden='true'
                    color='secondary'
                    sx={{ height: 2, mb: '2rem', width: 150 }}
                />
                <Box
                    sx={{
                        color: '#666B6E',
                        display: 'grid',
                        gridTemplateColumns: 'repeat(4, 1fr)',
                        fontFamily: 'Inter',
                        fontSize: '1rem',
                        mb: '2rem'
                    }}>
                    <Box>
                        <Typography color='secondary' variant='h5'>
                            Destinos
                        </Typography>
                        <List sx={{ padding: 0 }}>
                            <ListItem sx={{ padding: 0 }}>
                                <Link
                                    sx={{ py: '.5rem' }}
                                    color='inherit'
                                    underline='none'
                                    href='#'>
                                    Europa
                                </Link>
                            </ListItem>
                            <ListItem sx={{ padding: 0 }}>
                                <Link
                                    sx={{ py: '.5rem' }}
                                    color='inherit'
                                    underline='none'
                                    href='#'>
                                    América del norte
                                </Link>
                            </ListItem>
                            <ListItem sx={{ padding: 0 }}>
                                <Link
                                    sx={{ py: '.5rem' }}
                                    color='inherit'
                                    underline='none'
                                    href='#'>
                                    América del sur
                                </Link>
                            </ListItem>
                            <ListItem sx={{ padding: 0 }}>
                                <Link
                                    sx={{ py: '.5rem' }}
                                    color='inherit'
                                    underline='none'
                                    href='#'>
                                    Asía
                                </Link>
                            </ListItem>
                        </List>
                    </Box>
                    <Box>
                        <Typography color='secondary' variant='h5'>
                            Guías de viaje
                        </Typography>
                        <List sx={{ padding: 0 }}>
                            <ListItem sx={{ padding: 0 }}>
                                <Link
                                    sx={{ py: '.5rem' }}
                                    color='inherit'
                                    underline='none'
                                    href='/guias'>
                                    Todas las guías
                                </Link>
                            </ListItem>
                            <ListItem sx={{ padding: 0 }}>
                                <Link
                                    sx={{ py: '.5rem' }}
                                    color='inherit'
                                    underline='none'
                                    href='/guias/barcelona/informacion-principal-de-barcelona'>
                                    Guía de Barcelona
                                </Link>
                            </ListItem>
                            <ListItem sx={{ padding: 0 }}>
                                <Link
                                    sx={{ py: '.5rem' }}
                                    color='inherit'
                                    underline='none'
                                    href='/nueva-york/informacion-principal-de-nueva-york'>
                                    Guía de Nueva York
                                </Link>
                            </ListItem>
                            <ListItem sx={{ padding: 0 }}>
                                <Link
                                    sx={{ py: '.5rem' }}
                                    color='inherit'
                                    underline='none'
                                    href='/guias/madrid/informacion-principal-de-madrid'>
                                    Guía de Madrid
                                </Link>
                            </ListItem>
                            <ListItem sx={{ padding: 0 }}>
                                <Link
                                    sx={{ py: '.5rem' }}
                                    color='inherit'
                                    underline='none'
                                    href='/guias/paris/paris-main-information'>
                                    Guía de Paris
                                </Link>
                            </ListItem>
                        </List>
                    </Box>
                    <Box>
                        <Typography color='secondary' variant='h5'>
                            Especialidades
                        </Typography>
                        <List sx={{ padding: 0 }}>
                            <ListItem sx={{ padding: 0 }}>
                                <Link
                                    sx={{ py: '.5rem' }}
                                    color='inherit'
                                    underline='none'
                                    href='/blog'>
                                    Blog de viaje
                                </Link>
                            </ListItem>
                            <ListItem sx={{ padding: 0 }}>
                                <Link
                                    sx={{ py: '.5rem' }}
                                    color='inherit'
                                    underline='none'
                                    href='/sorprendeme'>
                                    Sorpréndeme
                                </Link>
                            </ListItem>
                        </List>
                    </Box>
                    <Box>
                        <Typography color='secondary' variant='h5'>
                            Trekzy
                        </Typography>
                        <List sx={{ padding: 0 }}>
                            <ListItem sx={{ padding: 0 }}>
                                <Link
                                    sx={{ py: '.5rem' }}
                                    color='inherit'
                                    underline='none'
                                    href='/quienes-somos#contacto'>
                                    Contacto
                                </Link>
                            </ListItem>
                            <ListItem sx={{ padding: 0 }}>
                                <Link
                                    sx={{ py: '.5rem' }}
                                    color='inherit'
                                    underline='none'
                                    href='/quienes-somos'>
                                    Quiénes somos
                                </Link>
                            </ListItem>
                            <ListItem sx={{ padding: 0 }}>
                                <Link
                                    sx={{ py: '.5rem' }}
                                    color='inherit'
                                    underline='none'
                                    href='/ayuda'>
                                    FAQs
                                </Link>
                            </ListItem>
                            <ListItem sx={{ padding: 0 }}>
                                <Link
                                    sx={{ py: '.5rem' }}
                                    color='inherit'
                                    underline='none'
                                    href='/legales'>
                                    Términos y Condiciones
                                </Link>
                            </ListItem>
                            <ListItem sx={{ padding: 0 }}>
                                <Link
                                    sx={{ py: '.5rem' }}
                                    color='inherit'
                                    underline='none'
                                    href='/politicas-de-privacidad'>
                                    Política de Privacidad
                                </Link>
                            </ListItem>
                             <ListItem sx={{ padding: 0 }}>
                                <Link
                                    sx={{ py: '.5rem' }}
                                    color='inherit'
                                    underline='none'
                                    href='/politicas-de-cancelaciones'>
                                    Política General de Modificaciones y Cancelaciones
                                </Link>
                            </ListItem>
                        </List>
                    </Box>
                </Box>
                <Divider
                    aria-hidden='true'
                    color='secondary'
                    sx={{ height: 2, mb: '1rem', width: '100%' }}
                />
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between'
                    }}>
                    <PayMethodsSection />
                    <SocialLinksSection />
                </Box>
            </Box>
            <img
                src={FooterImage}
                style={{
                    width: '30rem',
                    height: '30rem',
                    position: 'absolute',
                    top: '10%',
                    left: '62%'
                }}
            />
        </Container>
    </Box>
)

export default DeskopFooter
