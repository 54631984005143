import React from "react"
import { Link } from '@mui/material'
import "./imagecard.css"

const ImageCard = ({ title, image, country, city, hover }) => {
    const hoverEffectStar = `star-svg ${hover ? "onhover" : ""}`
    const hoverScale = `stars-container ${hover ? "onhover" : ""}`
    const hoverSubtitle = `subtitle ${hover ? "onhover" : ""}`

    return (
        <Link
            underline="none"
            href={`/tours/${city}`}
            color="primary.contrastText"
        >
            <div className="card">
                <div className="rating">
                    <div className={hoverScale}>
                        <span className={hoverEffectStar}>&#9733;</span>
                        <span className={hoverEffectStar}>&#9733;</span>
                        <span className={hoverEffectStar}>&#9733;</span>
                        <span className={hoverEffectStar}>&#9733;</span>
                        <span className={hoverEffectStar}>&#9733;</span>
                    </div>
                </div>
                <div className={hoverSubtitle}>
                    <h1>
                        {title}
                    </h1>
                </div>
                <img
                    src={image?.src || require('../../assets/images/new-york.png')}
                    alt={image?.caption || "default"}
                    className="card-image"
                />
                <div className="card-overlay"></div>
            </div>
        </Link>
    )
}

export default ImageCard
