import React from 'react'
import { Button, Container, Grid, Typography } from '@mui/material'
import MainLayout from '../../components/MainLayout'
import { ReactComponent as SadFaceIcon } from '../../assets/icons/sad-face.svg'

const ErrorPage = () => {
    const buttonStyles = {
        borderRadius: '16px',
        height: 'fit-content',
        color: '#ffffff',
        display: 'grid',
        textTransform: 'none',
        marginVertical: 4,
        width: '220px',
        '&:hover': {
            backgroundColor: 'secondary.main'
        }
    }

    return (
        <MainLayout>
            <Container
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    justifyContent: 'center',
                    py: { xs: '2rem', md: '5rem' },
                    width: '100%'
                }}>
                <SadFaceIcon style={{ width: '100%', height: '8rem' }} />
                <Typography
                    color="secondary"
                    component="h2"
                    sx={{
                        fontFamily: 'Inter',
                        fontSize: { xs: '1.5rem', md: '3rem' },
                        fontWeight: 700,
                        textAlign: 'center'
                    }}>
                    Oops…no encontramos la página que estás buscando. <br /> Prueba
                    con estos accesos directo:
                </Typography>
                <Grid
                    mt={4}
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        gap: 4
                    }}>
                    <Button
                        href="/ayuda"
                        variant="contained"
                        color="primary"
                        sx={buttonStyles}>
                        FAQs
                    </Button>
                    <Button
                        href="/checkout"
                        variant="contained"
                        color="primary"
                        sx={buttonStyles}>
                        Revisar el carrito
                    </Button>
                    <Button
                        href="/"
                        variant="contained"
                        color="primary"
                        sx={{ ...buttonStyles, marginRight: 0 }}>
                        Volver a la Home
                    </Button>
                </Grid>
            </Container>
        </MainLayout>
    )
}

export default ErrorPage
