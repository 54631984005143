import React from "react";

import { Radio } from "@mui/material";

import { ReactComponent as CheckBox } from '../../assets/icons/check-box.svg'
import { ReactComponent as UnCheckBox } from '../../assets/icons/uncheck-box.svg'


const BpRadio = (props) => {
    return (
        <Radio
            disableRipple
            color="default"
            checkedIcon={<CheckBox />}
            icon={<UnCheckBox />}
            {...props}
        />
    );
}

export default BpRadio;