import React from 'react'
import { Typography } from '@mui/material'

const ListItemHeader = ({ children, styles = {} }) => (
    <Typography
        color='secondary'
        sx={{
            fontSize: 24,
            fontFamily: 'Poppins',
            fontWeight: 500,
            lineHeight: 1.2,
            my: 3,
            pl: 2,
            ...styles,
        }}
    >
        {children}
    </Typography>
)

export default ListItemHeader
