import React, { useState } from "react";

import { Box, MenuItem, Select, Typography } from "@mui/material";

const CmLabel = (props) => {
    const { children, ...lProps } = props;
    return (
        <label style={{color: '#6D7280', fontSize: '14px'}} {...lProps}>{children}</label>
    );
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const assistants = [
    'Familia',
    'Amigos',
];

const languages = [
    'Ingles',
    'Espanol'
]

const Step1 = () => {
    const [assistant, setAssistant] = useState('Familia')
    const [language, setLanguage] = useState('Ingles')

    const handleChangeAssitants = (event) => {
        setAssistant(event.target.value);
    }

    const handleChangeLanguages = (event) => {
        setLanguage(event.target.value);
    }

    return (
        <>
            <Typography
                variant="h5"
                sx={{
                    fontSize: '16px',
                    color: 'secondary.main'
                }}
            >
                Tu Tour
            </Typography>
            <Box
                sx={{
                    display: 'grid',
                    width: '100%',
                    py: '5px'
                }}
            >
                <CmLabel htmlFor="assistant">¿Con quien vas?</CmLabel>
                <Select
                    labelId="assistant-id"
                    name="assistant"
                    value={assistant}
                    onChange={handleChangeAssitants}
                    MenuProps={MenuProps}
                    sx={{
                        fontSize: '13px',
                        height: '40px',
                        borderRadius: '8px'
                    }}
                    >
                    {assistants.map((item) => (
                        <MenuItem
                            key={item}
                            value={item}
                            >
                            {item}
                        </MenuItem>
                    ))}
                </Select>
            </Box>

            <Box
                sx={{
                    display: 'grid',
                    width: '100%',
                    py: '5px'
                }}
            >
                <CmLabel htmlFor="language">¿En que idioma quieres el tour?</CmLabel>
                <Select
                    labelId="language-id"
                    name="language"
                    value={language}
                    onChange={handleChangeLanguages}
                    MenuProps={MenuProps}
                    sx={{
                        fontSize: '13px',
                        height: '40px',
                        borderRadius: '8px'
                    }}
                    >
                    {languages.map((item) => (
                        <MenuItem
                            key={item}
                            value={item}
                            >
                            {item}
                        </MenuItem>
                    ))}
                </Select>
            </Box>
        </>
    )
}

export default Step1;