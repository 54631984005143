import React from "react";
import { Box, Typography, } from "@mui/material"
import { ReactComponent as VisaLogo } from "../../assets/icons/visa-white.svg";
import { ReactComponent as MarsterCardLogo } from "../../assets/icons/mastercard-white.svg";
import { ReactComponent as StripeLogo } from "../../assets/icons/stripe.-white.svg";

const PayMethodsSection = () => (
    <Box sx={{mb: "2rem"}}>
        <Typography sx={{
            color: "primary.contrastText",
            fontSize: {xs: "12px", md: "14px"},
            textAlign: "left",
            my: "1rem",
        }}>
            Compromiso de seguridad en pagos electrónicos
        </Typography>
        <Box sx={{
            display: "flex",
            justifyContent: "start",
            px: 0,
            gap: "1rem"
        }}>
            <VisaLogo />
            <MarsterCardLogo />
            <StripeLogo />
        </Box>
    </Box>

)

export default PayMethodsSection;
