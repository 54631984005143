import React from 'react'
import {
    AppBar,
    Box,
    Link,
    Typography
} from '@mui/material'

const Header2 = ({ logoImgSource }) => {
    return (
        <Box>
            <AppBar
                sx={{
                    px: { xs: '1%', md: '6%', lg: '8%' },
                    py: '8px',
                    position: 'initial'
                }}
            >
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}>
                    <Link href="/">
                        <img
                            alt="toursprivados.com"
                            src={logoImgSource}
                            style={{
                                width: '6rem',
                                height: '4rem',
                                paddingTop: '.5rem'
                            }}
                        />
                    </Link>
                    <Typography>
                        ¿Necesitas ayuda? <b>Contacta con Trekzy</b>
                    </Typography>
                </Box>
            </AppBar>
        </Box>
    )
}

export default Header2
