import React, { act, useEffect } from "react"
import Hero from "../../components/Hero";
import heroImage from "../../assets/images/red-pin-hero.png"
import Breadcrumbs from "../../components/Breadcrumbs";
import { Container } from "@mui/material";
import CardsGrid from "../../components/CardsGrid";
import MainLayout from "../../components/MainLayout";
import { opacities } from "../../components/BlurImageWrapper";
import { cards } from "./mockData";
import useFetchCountries from "../../hooks/API/Destinations/useFetchCountries";

/**
 * 
  {
        name: "Italia",
        cities: "12",
        activities: 10000,
        path: "destinos/italia",
        image: require("../../assets/images/rome.png")
    }
 */
const DestinationsPrincipal = () => {

    const { countries: apiCountries, isLoading } = useFetchCountries();
    const [gridCards, setGridCards] = React.useState([]);

    useEffect(() => {
        if (isLoading) return;

        if (apiCountries?.countries) {
            const gridCards = apiCountries?.countries.map(item => {
                return {
                    name: item.name,
                    cities: item.total_cities,
                    activities: item.total_tours,
                    path: `destinos/${item.slug}`,
                    image: item.image
                }
            });


            setGridCards(gridCards)
        }




    }, [apiCountries, isLoading])


    return (
        <MainLayout>
            <Hero
                bgImage={heroImage}
                opacity={opacities.lighter}
                renderInputSearch={false}
                text="+400 destinos con más de 20.000 actividades"
                title="Destinos"
            />
            <Container>
                <Breadcrumbs breadcrumbs={[{
                    name: "Destinos",
                    path: "destinos"
                }]} />
                {gridCards && <CardsGrid cards={gridCards} />}
            </Container>
        </MainLayout>
    )
}

export default DestinationsPrincipal;
