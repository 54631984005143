import React from 'react'
import { useLocation } from 'react-router-dom'
import { Box, Container, Grid, Typography } from '@mui/material'
import BlurImageWrapper, { orientations } from '../../components/BlurImageWrapper'
import MainLayout from '../../components/MainLayout'
import teamMembers from '../../assets/images/teamwork.jpg'
import SingleCard from '../../components/AboutUs/SingleCard'
import SingleProductCard from '../../components/AboutUs/SingleProductCard'
import ImagesBanner from '../../components/AboutUs/ImagesBanner'
import ContactUs from '../../components/AboutUs/ContactUs'
import TeamSection from '../../components/AboutUs/TeamSection'
import TagButton from '../../components/TagButton'
import SubscribeBanner from '../../components/AboutUs/SubscribeBanner'

const AboutUs = () => {
    const { hash } = useLocation();
    
    React.useEffect(() => {
        if (hash) {
            const id = hash.replace('#', '')
            document.getElementById(id)?.scrollIntoView({
                behavior: 'smooth'
            })
        }
    }, [hash])

    return (
        <MainLayout>
            <BlurImageWrapper
                imageUrl={teamMembers}
                altText='Sobre Nosotros- Quienes somos'
                orientation={orientations.bottomToTop}
                styles={{ backgroundPosition: 'top' }}>
                <Container
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        py: '8rem',
                        maxWidth: '1280px'
                    }}>
                    <Typography
                        color='secondary.contrastText'
                        variant='h3'
                        component='h1'
                        sx={{ mb: '1rem', fontWeight: 'bold' }}>
                        Quiénes somos
                    </Typography>
                    <Typography
                        variant='body1'
                        color='secondary.contrastText'
                        sx={{
                            textAlign: 'center',
                            maxWidth: 1024
                        }}></Typography>
                </Container>
            </BlurImageWrapper>
            <Container
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    gap: 2,
                    mt: { xs: '2rem', md: '4rem' },
                    px: { md: '2rem' },
                    width: '100%'
                }}>
                <SingleCard
                    title='Sobre Trekzy'
                    description='En ToursPrivados.com nos dedicamos a ofrecer una nueva forma de viajar a quienes buscan una experiencia única con guías locales. Nuestro equipo trabaja para encontrar los mejores operadores turísticos para que tú, tus amigos y tu familia creéis recuerdos para toda la vida.'
                    style={{ marginRight: '2rem' }}
                />
                <SingleCard
                    title='Lo que nos hace únicos'
                    description='Somos los primeros en ofrecerte un servicio de reserva de tours privadas online…. Nos especializamos en experiencias de alto valor y 100% personalizables. En nuestra web podrás configurar la forma y duración de tu tour para que encaje con tus necesidades.'
                />
            </Container>
            <Container
                sx={{
                    width: '100%',
                    my: '4rem'
                }}>
                <Grid container columnSpacing={1} rowSpacing={1}>
                    <Grid
                        xs={12}
                        md={6}
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            mb: '1rem'
                        }}>
                        <SingleProductCard
                            image='happy-couple.jpg'
                            title='Con el foco en la experiencia'
                            description='En ToursPrivados.com priorizamos la experiencia de cada viajero, por eso damos una atención personalizada. Te solucionamos cualquier problema con tu reserva en menos de 24h. Nunca tendrás que pagar más de lo que te mostramos en el ticket.'
                        />
                    </Grid>
                    <Grid
                        xs={12}
                        md={6}
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            mb: '1rem'
                        }}>
                        <SingleProductCard
                            image='young-traveler.jpg'
                            title='Creemos en la sostenibilidad'
                            description='Somos conscientes del impacto que tiene el turismo en las ciudades, es por eso que promovemos un turismo más respetuoso con los locales y de mejor calidad para los viajeros.'
                        />
                    </Grid>
                    <Grid
                        xs={12}
                        md={6}
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            mb: '1rem'
                        }}>
                        <SingleProductCard
                            image='teacher.jpg'
                            title='Internacionales'
                            description='Estamos en los 5 continentes. Trabajamos con guías locales y apoyamos la generación económica de la zona.'
                        />
                    </Grid>
                    <Grid
                        xs={12}
                        md={6}
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            mb: '1rem'
                        }}>
                        <SingleProductCard
                            image='tutor.jpg'
                            title='Sin barreras lingüísticas'
                            description='No importa de dónde seas, nuestros partners poseen una lista de idiomas con los que pueden operar la experiencia. Si tu idioma no está en la lista mándanos un email y lo resolveremos.'
                        />
                    </Grid>
                </Grid>
            </Container>
            <TeamSection />
            <Container
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    mb: { xs: '1.5rem', md: '2rem' },
                    textAlign: 'center'
                }}>
                <Typography
                    variant='title'
                    color='secondary.main'
                    sx={{ mt: { xs: '3rem', md: '4rem' }, mb: '1rem' }}>
                    Hecho por y para viajeros
                </Typography>
                <Typography textAlign='center' mb={2}>
                    A todo nuestro equipo le encanta viajar y conocer lugares
                    nuevos. Cada tour está seleccionado y curado por nosotros
                    para que tengas una experiencia óptima
                </Typography>
            </Container>
            <ImagesBanner />
            <Container sx={{ mt: {xs: '2rem', md: '4rem'} }}>
                <Typography textAlign='center'>
                    Sabemos lo que hace especial a cada destino porque lo hemos vivido. Nos aseguramos de que cada tour refleje nuestra pasión por viajar, brindándote aventuras auténticas y memorables
                </Typography>
                <Typography
                    component='h2'
                    variant='title'
                    color='secondary'
                    sx={{
                        display: { xs: 'none', md: 'block' },
                        mt: '3rem',
                        mb: '1rem',
                        textAlign: 'center'
                    }}>
                    Más sobre nosotros
                </Typography>
                <Typography
                    component='p'
                    variant='textbody2'
                    sx={{
                        textAlign: 'center',
                        mb: '3rem',
                        display: { xs: 'none', md: 'block' }
                    }}>
                   Si sigues queriendo saber de lo que va ToursPrivados, mira el siguiente vídeo.
                </Typography>
                <Box width='100%' mb={8} sx={{
                    display: { xs: 'none', md: 'block' }
                }}>
                    <iframe
                        width='100%'
                        height='415'
                        src='https://www.youtube.com/embed/2Kr8jds3S-c?si=2T6GFYmiEuBxK54r'
                        title='YouTube video player'
                        frameBorder='0'
                        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                        referrerPolicy='strict-origin-when-cross-origin'
                        style={{
                            borderRadius: 8,
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        alignItems: 'center',
                        display: { xs: 'flex', md: 'none' },
                        flexDirection: 'column',
                        justifyContent: 'center',
                        textAlign: 'center',
                        mb: '2.5rem'
                    }}>
                    <Typography color='secondary' fontSize={18} mb={1} sx={{ mt: {xs: 4, md: 0}}}>
                        Contacto
                    </Typography>
                    <Typography
                        color='secondary'
                        fontSize={24}
                        fontWeight={700}
                        mb={2}>
                        Nuestros expertos están aquí para ayudarte a conectar
                        con tu aventura perfecta
                    </Typography>
                    <TagButton
                        text='Contacta con Trekzy'
                        href='tel:+34666666666'
                        sx={{
                            borderRadius: 7,
                            fontSize: 19,
                            py: '.5rem',
                            width: '15rem'
                        }}
                    />
                </Box>
            </Container>
            <section id='contacto'>
                <ContactUs />
            </section>
            <SubscribeBanner />
        </MainLayout>
    )
}

export default AboutUs
