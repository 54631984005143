import React from "react"
import { Accordion, AccordionDetails, AccordionSummary, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material"
import { ArrowDropDownIcon } from "@mui/x-date-pickers"


const RadioButtonsFilter = ({ title, options, onChange }) => (
    <Accordion sx={{ mb: '25px', borderRadius: '8px' }}>
        <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls={`${title}-content`}
            id={`${title}-header`}
        >
            <Typography>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <FormControl>
                <RadioGroup
                    aria-labelledby={`radio-buttons-group-${title}`}
                    name="radio-buttons-filter"
                    onChange={onChange}
                >
                    {options.map(option => (
                        <FormControlLabel
                            key={option}
                            value={option}
                            control={<Radio />}
                            label={option}
                        />
                    ))}
                </RadioGroup>
            </FormControl>
        </AccordionDetails>
    </Accordion>
)

export default RadioButtonsFilter
