import React, { useEffect } from 'react';
import {
    Card,
    CardMedia,
    CardContent,
    Link,
    Typography,
    CardActions,
    Box,
    Button
} from '@mui/material';
import { ReactComponent as XSquare } from '../../assets/icons/square-cancel.svg'
import { ReactComponent as Clock } from '../../assets/icons/clock.svg'
import { ReactComponent as Globe } from '../../assets/icons/globe.svg'
import './styles.css';

const ProductCard2 = ({ images, price, rate, reviewsTotal, name, description, path }) => {

    const [reviewStatus, setReviewStatus] = React.useState("");
    const [rating, setRaiting] = React.useState(7);


    useEffect(() => {
        if (reviewsTotal >= 0 && reviewsTotal < 250) {
            setReviewStatus("Bueno");
            setRaiting(7);
        } else if (reviewsTotal >= 250 && reviewsTotal <= 1000) {
            setReviewStatus("Muy bueno");
            setRaiting(9);
        } else if (reviewsTotal > 1000) {
            setReviewStatus("Excelente");
            setRaiting(10);
        } else {
            setReviewStatus("Sin calificación");
        }
    }, [reviewsTotal])



    return (
        <Link href={path} sx={{ textDecoration: "none" }}>
            <Card
                id="product-card-2"
                sx={{
                    display: { xs: 'block', md: 'flex' },
                    height: { xs: '330px', md: '190px' },
                    pr: { xs: 0, md: '10px' },
                    my: '15px'
                }}>
                <CardMedia
                    sx={{
                        width: { xs: '100%', md: '160px' },
                        height: { xs: '140px', md: '100%' }
                    }}
                    image={images}
                    title={name}
                />
                <Box
                    sx={{
                        width: '100%',
                        display: { xs: 'block', md: 'flex' },
                        flexDirection: 'column'
                    }}
                >
                    <CardContent
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}
                    >
                        <Typography
                            sx={{ fontSize: '18px!important' }}
                            variant="subtitle1"
                        >{name}</Typography>
                        <Button
                            sx={{
                                fontSize: { xs: '9px', md: '10px' },
                                py: '2px',
                                height: { xs: 'fit-content', md: 'auto' }
                            }}
                            variant="contained"
                        >Ver más</Button>
                    </CardContent>
                    <CardContent sx={{ pt: '0px' }}>
                        <Typography
                            sx={{
                                display: { xs: 'none', md: 'block' },
                                fontSize: '13px',
                            }}
                            variant='body2'
                            component='p'
                        >{description}</Typography>
                        <Typography
                            sx={{
                                display: { xs: 'block', md: 'none' },
                                fontSize: '13px',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis'
                            }}
                            variant='body2'
                            component='p'
                        >{description}</Typography>
                    </CardContent>
                    <CardActions sx={{ pt: 0, pl: 2 }}>
                        <Box sx={{
                            display: { xs: 'none', md: 'flex' }
                        }}>
                            <XSquare />
                            <Typography
                                variant='body2'
                                component='p'
                                color='secondary'
                            >&nbsp;Cancelación gratuita&nbsp;</Typography>
                            <Clock />
                            <Typography
                                variant='body2'
                                component='p'
                                color='secondary'
                            >&nbsp;De 4 a 5 horas&nbsp;</Typography>
                            <Globe />
                            <Typography
                                variant='body2'
                                component='p'
                                color='secondary'
                            >&nbsp;Idiomas: Español e Inglés</Typography>
                        </Box>
                        <Box sx={{
                            display: { xs: 'flex', md: 'none' },
                            ml: '0px!important'
                        }}>
                            <XSquare />
                            <Typography
                                variant='body2'
                                component='p'
                                color='primary'
                            >&nbsp;Cancelación gratuita&nbsp;</Typography>
                        </Box>
                    </CardActions>
                    <CardActions sx={{ py: 0, display: 'flex', justifyContent: 'space-between' }}>
                        <Box sx={{ display: 'flex' }}>
                            <Box
                                sx={{
                                    width: 40,
                                    height: 28,
                                    borderRadius: 2,
                                    bgcolor: 'secondary.main',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    marginLeft: 0.5,
                                }}>
                                <Typography
                                    variant="h6"
                                    color="primary.contrastText"
                                    sx={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                                    {rating}
                                </Typography>
                            </Box>
                            <Typography
                                mb={2}
                                pl={1}
                                fontFamily={'Poppins'}
                                sx={{
                                    display: { xs: 'none', md: 'flex' },
                                    lineHeight: '1.9'
                                }}>
                                {`${reviewStatus}`}
                            </Typography>
                            <Typography
                                mb={2}
                                fontFamily={'Poppins'}
                                sx={{
                                    fontSize: { xs: '14px', md: '16px' },
                                    lineHeight: '1.9'
                                }}>
                                {`(${reviewsTotal} Reviews)`}
                            </Typography>
                        </Box>
                        <Box>
                            <Typography
                                color="secondary"
                                sx={{
                                    fontSize: { xs: '14px', md: '18px' },
                                    fontWeight: { xs: 600, md: 700 }
                                }}>
                                {`Desde ${price?.from} €`}
                            </Typography>
                            <Typography
                                color="grayText"
                                sx={{
                                    textAlign: 'right',
                                    fontSize: { xs: '10px', md: '12px' },
                                    fontWeight: 400
                                }}>
                                IVA incluido
                            </Typography>
                        </Box>
                    </CardActions>
                </Box>
            </Card>
        </Link>
    )
};

export default ProductCard2;
