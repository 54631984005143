export const getAssistantsString = (pax) => {
    return pax.reduce((accumulator, currentValue, index) => {
        
        if (currentValue.name === "Infante") {
            accumulator = accumulator + currentValue.total + " niños"
        } else if (currentValue.name === "Adulto") {
            accumulator = accumulator + currentValue.total + " adultos"
        } else if (currentValue.name === "Bebe") {
            accumulator = accumulator + currentValue.total + " bebes"
        }

        if (index !== 1) accumulator = accumulator + ", "
        return accumulator;

    }, "");
}

export const getTotalAssistants = (pax) => {
    return pax.reduce((accumulator, currentValue) => accumulator + currentValue.total, 0);
}