import React from "react";

import { FormControlLabel } from "@mui/material";
import BpRadio from "../BpRadio";

const AGREEMENT_LABEL_1 = `Deseo guardar mi tarjeta para siguientes compras`;
const AGREEMENT_LABEL_2 = `Quiero que se envíe una notificación en PDF a mi correo`;

const Agreements = ({handleChange, agreement1, agreement2}) => {
    return (
        <>
            <FormControlLabel sx={{
                '& .MuiTypography-body1': {
                    fontSize: {xs: '10px', md: '12px'}
                },
                '& .MuiFormControlLabel-labelPlacementEnd': {
                    alignItems: 'flex-start'
                },
            }}
                value="agreement1"
                label={AGREEMENT_LABEL_1}
                control={
                    <BpRadio
                        checked={agreement1}
                        onChange={handleChange}
                        value="agreement1"
                        name={`radio-buttons-agreement1`}
                        inputProps={{ 'aria-label': 'agreement1' }}
                    />
                }
            />
            <FormControlLabel sx={{
                '& .MuiTypography-body1': {
                    fontSize: {xs: '10px', md: '12px'}
                },
                '& .MuiFormControlLabel-labelPlacementEnd': {
                    alignItems: 'flex-start'
                }
            }}
                value="agreement2"
                label={AGREEMENT_LABEL_2}
                control={
                    <BpRadio
                        checked={agreement2}
                        onChange={handleChange}
                        value="agreement2"
                        name={`radio-buttons-agreement2`}
                        inputProps={{ 'aria-label': 'agreement2' }}
                    />
                }
            />
        </>
    )
}

export default Agreements;