import React from 'react'
import axios from 'axios'

const useFetchGuideByURL = (city, section, preview) => {
    const [guide, setGuide] = React.useState({});
    const [isLoading, setIsLoading] = React.useState(false);


    const fetchGuides = React.useCallback(async () => {
        setIsLoading(true)
        const { REACT_APP_BASE_URL } = process.env
        const token = localStorage.getItem('token');
        const params = {
            city,
            section,
        }

        if (preview) {
            params.preview = true
        }

        try {
            const { data } = await axios.get(
                `${REACT_APP_BASE_URL}/index.php?r=api/destinationguides`,
                {
                    headers: {
                        'x-authorization-token': token
                    },
                    params: params
                }
            ) || {}

            setIsLoading(false)
            if (data.length) {
                setGuide(data[0])
            }
        } catch (err) {
            console.error('Error fetching guide data: ', err)
            setIsLoading(false)
        }
    }, [city, section])

    React.useEffect(() => {
        fetchGuides()
    }, [fetchGuides])

    return {
        guide,
        isLoading,
    }
}

export default useFetchGuideByURL
