import React from 'react'
import Hero from '../../components/Hero';
import heroImage from '../../assets/images/paris.png'
import Breadcrumbs from '../../components/Breadcrumbs';
import { Container } from '@mui/material';
import CardsGrid from '../../components/CardsGrid';
import MainLayout from '../../components/MainLayout';
import { opacities } from '../../components/BlurImageWrapper';
import { useParams } from 'react-router-dom';
import useFetchCities from '../../hooks/API/Destinations/useFetchCities';

const mockedCard = {
    name: 'Paris',
    activities: 90000,
    path: 'destinos/francia/paris',
    image: require('../../assets/images/paris.png')
}

const DestinationsCountry = () => {

    const params = useParams();
    const country = params.country;
    const { cities, isLoading } = useFetchCities({ country });
    const [gridCards, setGridCards] = React.useState([]);
    // const [gridCards, setGridCards] = React.useState([]);

    /*
    {"name":"Paris","activities":90000,"path":"destinos/francia/paris","image":"/static/media/paris.d8ac660d8d3cb4e320ad.png"}
    */
    React.useEffect(() => {
        if (isLoading) return;

        if (cities?.cities) {
            const allcities = cities?.cities.map(item => {
                return {
                    name: item.name,
                    activities: item.total_tours,
                    path: `/tours/${item.slug}`,
                    image: item.image
                }
            });
            console.log(allcities)
            setGridCards(allcities)
        }
    }, [cities, isLoading])

    return (
        <MainLayout>
            <Hero
                bgImage={heroImage}
                opacity={opacities.lighter}
                renderInputSearch={false}
                title='Francia'
                titleHeader='Tours privados'
            />
            <Container>
                <Breadcrumbs breadcrumbs={[
                    {
                        name: 'Destinos',
                        path: 'destinos'
                    },
                    {
                        name: 'Francia',
                        path: '#'
                    }
                ]} />
                {gridCards && <CardsGrid cards={gridCards} />}
            </Container>
        </MainLayout>
    )
}

export default DestinationsCountry
