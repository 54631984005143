import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { atracciones, categorias, duraciones, timeSlider, priceSlider } from "../../utils/destinationPageMockData";
import GroupCheckBoxesFilter from "./GroupCheckBoxesFilter";
import SearchInput from "../SearchInput"
import dayjs from "dayjs";
import RangeSlider from "./RangeSlider"
import Calendar from "./Calendar"



const Filters = () => {
  const [boxAtractions, setBoxAtractions] = useState(atracciones);
  const [boxCategories, setBoxCategories] = useState(categorias);
  const [boxDurations, setBoxDurations] = useState(duraciones);
  const [date, setDate] = useState(dayjs('2024-04-17'));
  const [valueSlider1, setValueSlider1] = useState([11, 15]);
  const [valueSlider2, setValueSlider2] = useState([50, 160]);

  const handleChangeSlider1 = (event, newValue) => {
    setValueSlider1(newValue);
  }

  const handleChangeSlider2 = (event, newValue) => {
    setValueSlider2(newValue);
  }

  const handleChangeCalendar = (event, newDate) => {
    setDate(newDate)
  }

  function makeHandler(checkBoxes, setCheckBoxes) {
      return function (id) {
          setCheckBoxes(checkBoxes.map(item => item.id == id ? {...item, isChecked: !item.isChecked} : item))
      }
  }
    return (
      <>
        <Box sx={{
          px: { xs: '15px', md: 0 },
          mb: '100px'
        }}>
          <Box sx={{
            pb: '10px',
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between'
          }}>
            <Typography
              sx={{
                color: '#000000'
              }}>Filtros</Typography>
            <Button
              sx={{
                pt: 0,
                display: {xs: 'block', md: 'none'},
                color: '#666B6E'
              }}>Borrar</Button>
          </Box>
          <Calendar title="Seleccione una fecha" date={date} handleChange={handleChangeCalendar}/>
          <SearchInput />
          <GroupCheckBoxesFilter
              title="Atracciones"
              checkBoxes={boxAtractions}
              handleOnChange={makeHandler(boxAtractions, setBoxAtractions)}
          />
          <GroupCheckBoxesFilter
              title="Categorias"
              checkBoxes={boxCategories}
              handleOnChange={makeHandler(boxCategories, setBoxCategories)}
          />
          <RangeSlider title="Hora de inicio" {...timeSlider} valueSlider={valueSlider1} handleChange={handleChangeSlider1} />
          <GroupCheckBoxesFilter
            title="Categorias"
            checkBoxes={boxDurations}
            handleOnChange={makeHandler(boxDurations, setBoxDurations)}
          />
          <RangeSlider title="Precio" {...priceSlider} valueSlider={valueSlider2} handleChange={handleChangeSlider2} />

          <Box sx={{
            width: '100%',
            display: {xs: 'none', md: 'flex'},
            justifyContent: 'space-between'
          }}>
            <Button
              sx={{
                fontSize: '12px',
                pt: 0,
                color: '#666B6E',
                fontWeight: 500
              }}>Borrar Filtros</Button>
            <Button
              sx={{
                fontSize: '13px',
                width: '120px',
              }}
              variant="contained"
            >Aplicar</Button>
          </Box>
        </Box>
        <Box sx={{
          width: '100%',
          backgroundColor: '#ffffff',
          display: {xs: 'flex', md: 'none'},
          justifyContent: 'space-between',
          py: '20px',
          px: '10px',
          boxShadow: 10,
          position: 'fixed',
          bottom: 0
        }}>
          <Button
            sx={{
              fontSize: '13px',
              width: '100%',
            }}
            variant="contained"
          >Aplicar</Button>
        </Box>
      </>

    )
}

export default Filters
