import React, { useContext } from "react";

import { ReactComponent as Edit } from '../../../assets/icons/edit-2.svg'
import { ReactComponent as Trash } from '../../../assets/icons/trash.svg'
import { Box, Divider, Grid, IconButton, Typography } from "@mui/material";
import CheckoutContext from "../../../components/CheckoutContext/checkoutContext";
import { getAssistantsString } from "../../../utils/checkout";
import dayjs from "dayjs";

const SimpleProductCard = ({data}) => {
    const {
        id,
        imageUrl,
        price,
        rate,
        reviews,
        title,
        description,
        languages,
        cancelable,
        time,
        date = '',
        type = '',
        assistants = '',
        moreDetails,
        edit,
        remove,
        optionals,
    } = data;
    const { pax } = optionals;
    const context = useContext(CheckoutContext);
    return (
        <Box sx={{pb: '50px'}}>
            <Grid container>
                <Grid
                    item
                    xs={3}
                    sx={{px: '0!important'}}
                >
                    <Box
                        sx={{
                            borderRadius: '8px',
                            overflow: 'hidden',
                            height: '55px',
                            width: '55px'
                        }}
                    >
                        <img
                        src={require(`../../../assets/${imageUrl}`)}
                        alt={title}
                        height={55}
                        width={55}
                    />
                    </Box>
                </Grid>
                <Grid
                    item
                    xs={7}
                    sx={{pl: '5px!important'}}
                >
                    <Typography
                        sx={{ fontSize: '13px!important' }}
                        variant="subtitle1"
                    >{title}</Typography>
                    {(date || type || assistants) && <Box
                        sx={{
                            display: 'block',
                            py: 0,
                            pb: '10px'
                        }}
                    >
                        {date && <Typography
                            variant='body2'
                            component='p'
                            sx={{fontSize: '13px'}}
                        >{dayjs(date).format('MMMM D, YYYY')}</Typography>}
                        {type && <Typography
                            variant='body2'
                            component='p'
                            sx={{
                                ml: '0!important',
                                fontSize: '13px'
                            }}
                        >{type}</Typography>}
                        {pax && <Typography
                            variant='body2'
                            component='p'
                            sx={{
                                ml: '0!important',
                                fontSize: '13px'
                            }}
                        >{getAssistantsString(pax)}</Typography>}
                    </Box>}
                </Grid>
                <Grid
                    item
                    xs={2}
                    sx={{
                        px: '0!important',
                        display: 'grid'
                    }}
                >
                    <IconButton
                        onClick={() => context.handleEditTour(id)}
                        aria-label='Editar'
                        sx={{
                            height: '17px',
                            px: '3px',
                            py: 0
                        }}
                    >
                        <Edit />
                    </IconButton>
                    <IconButton
                        onClick={() => context.handleRemoveTour(id)}
                        aria-label='Remover'
                        sx={{
                            height: '17px',
                            px: '3px',
                            py: 0
                        }}
                    >
                        <Trash />
                    </IconButton>
                    <Typography
                        sx={{
                            fontSize: '12px',
                            fontWeight: 500,
                        }}>
                        {`${price}€`}
                    </Typography>
                </Grid>
            </Grid>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center'
                }}
            >
                <Divider
                    aria-hidden="true"
                    sx={{
                        color: '#666B6E',
                        height: 3,
                        width: '5%'
                    }}
                />
            </Box>
        </Box>
    )
}

export default SimpleProductCard;