/* eslint-disable no-undef */
import React from 'react';
import {
    Card,
    CardMedia,
    CardContent,
    Typography,
    CardActions,
    Box,
    IconButton
} from '@mui/material';
import './styles.css';
import { ReactComponent as Edit } from '../../../assets/icons/edit-2.svg'
import { ReactComponent as Trash } from '../../../assets/icons/trash.svg'
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import { getAssistantsString } from '../../../utils/checkout';

dayjs.locale('es');

const CartProductCard = ({
    data,
    handleEditTour,
    handleRemoveTour
}) => {
    const {
        id,
        imageUrl,
        price,
        rate,
        reviews,
        title,
        description,
        languages,
        cancelable,
        time,
        date = '',
        type = '',
        assistants = '',
        moreDetails,
        edit,
        remove,
        optionals,
    } = data;
    const { pax } = optionals;
    const image = require(`../../../assets/${imageUrl}`)

    return (
        <Card
            id="cart-product-card"
            sx={{
                display: 'flex',
                height: 'auto',
                pr: '10px',
                my: '15px',
                borderRadius: '8px',
                boxShadow: '0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12)'
            }}>
            <CardMedia
                sx={{
                    width: '160px',
                    height: 'auto'
                }}
                image={image}
                title={title}
            />
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <CardContent
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        pr: '4px',
                        py: '5px'
                    }}
                >
                    <Typography
                        sx={{ fontSize: '16px!important' }}
                        variant="subtitle1"
                    >{title}</Typography>
                    
                </CardContent>
                <CardActions sx={{
                    pl: '16px',
                    py: 0,
                }}>
                    {(date || assistants) && <Box
                        sx={{
                            display: 'block',
                            py: 0,
                        }}
                    >
                        {date && <Typography
                            variant='body2'
                            component='p'
                            sx={{fontSize: '13px'}}
                        >{dayjs(date).format('MMMM D, YYYY')}</Typography>}
                        {pax && <Typography
                            variant='body2'
                            component='p'
                            sx={{
                                ml: '0!important',
                                fontSize: '13px'
                            }}
                        >{getAssistantsString(pax)}</Typography>}
                    </Box>}
                </CardActions>
                <CardActions sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    pl: '16px',
                    py: 0,
                    pb: '5px'
                }}>
                    <Box>
                        <Typography
                            sx={{
                                fontSize: '15px',
                                fontWeight: 700,
                                color: 'secondary.main'
                            }}>
                            {`${price} €`}
                        </Typography>
                        <Typography
                            color="grayText"
                            sx={{
                                textAlign: 'right',
                                fontSize: '12px',
                                fontWeight: 400
                            }}>
                            IVA incluido
                        </Typography>
                    </Box>
                    {(edit || remove) && <Box
                        sx={{
                            display: 'flex'
                        }}
                    >
                        {edit && <IconButton
                            onClick={() => handleEditTour(id)}
                            aria-label='Editar'
                            sx={{
                                height: '17px',
                                px: '3px',
                                py: 0
                            }}
                        >
                            <Edit />
                        </IconButton>}
                        {remove && <IconButton
                            onClick={() => handleRemoveTour(id)}
                            aria-label='Remover'
                            sx={{
                                height: '17px',
                                px: '3px',
                                py: 0
                            }}
                        >
                            <Trash />
                        </IconButton>}
                    </Box>}
                </CardActions>
            </Box>
        </Card>
    )
};

export default CartProductCard;
