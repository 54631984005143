import { Box, Container, Typography } from '@mui/material'
import GuideCard from './GuideCard'
import Carousel from 'react-material-ui-carousel'
import { useScreenDetector } from '../../hooks/useScreenDetector'

import { ReactComponent as CameraIcon } from '../../assets/icons/camera.svg'
import { ReactComponent as InfoIcon } from '../../assets/icons/information.svg'
import { ReactComponent as CampTentICon } from '../../assets/icons/camp-tent.svg'
import { ReactComponent as BagPackIcon } from '../../assets/icons/bagpack.svg'
import { ReactComponent as PlateIcon } from '../../assets/icons/plate.svg'
import { ReactComponent as AirBallonIcon } from '../../assets/icons/air-ballon.svg'

const cards = [
    {
        Icon: InfoIcon,
        title: 'Info principal',
        text: 'Un resumen claro y completo sobre la ciudad que quieres visitar, con detalles importantes para que sepas qué esperar.'
    },
    {
        Icon: CameraIcon,
        title: 'Qué ver',
        text: 'Una recopilación de lugares, eventos y festividades que se celebran en el destino para que planifiques bien tu viaje.'
    },
    {
        Icon: CampTentICon,
        title: 'Dónde dormir',
        text: 'Información sobre barrios y distritos. También listamos los hoteles más populares del destino.'
    },
    {
        Icon: BagPackIcon,
        title: 'Excursiones',
        text: 'Te ofrecemos una selección de los mejores tours y experiencias únicas en el destino, para que puedas organizar tu viaje de manera fácil y cómoda.'
    },
    {
        Icon: PlateIcon,
        title: 'Gastronomia',
        text: 'Sabemos que ningún viaje está completo sin haber probado la comida de allí. Por eso te mostramos los platos y bebidas más típicos de la ciudad, además de recomendarte los mejores bares y restaurantes.'
    },
    {
        Icon: AirBallonIcon,
        title: 'Antes de ir',
        text: 'Todo lo que necesitas saber antes de partir: moneda, idioma, costumbres y qué llevar en la maleta para para hacer tu vida más fácil.'
    }
]

const GuideCards = () => {
    const { isMobile } = useScreenDetector()
    return (
        <Container
            sx={{
                display: 'flex',
                flexDirection: 'column',
                py: '3rem',
                textAlign: 'center'
            }}>
            <Typography
                color="secondary"
                variant="subtitle1"
                sx={{
                    fontSize: { xs: 22, md: 24 },
                    fontWeight: 600
                }}>
                <span>¿Necesitas una mano?</span>
            </Typography>
            <Typography color="primary" variant="subtitle" sx={{ mb: '2rem' }}>
                Explora nuestras guías
            </Typography>
            <Typography
                sx={{
                    color: '#3E3A39',
                    fontFamily: 'Inter',
                    fontSize: { xs: 16, md: 20 },
                    mb: { xs: '1rem', md: '3rem' }
                }}>
                Nuestras guías se componen de 6 apartados. Cada uno enfocado en
                darte información específica sobre un tema en concreto.
            </Typography>
            {isMobile ? (
                <Carousel
                    autoPlay={false}
                    sx={{ my: '20px', display: { xs: 'block', md: 'none' } }}>
                    {cards.map((card) => (
                        <GuideCard key={card.title} {...card} />
                    ))}
                </Carousel>
            ) : (
                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(3, 1fr)',
                        gap: 2.5,
                        mb: '5rem'
                    }}>
                    {cards.map((card) => (
                        <GuideCard
                            key={card.title}
                            {...card}
                            alignContent="left"
                        />
                    ))}
                </Box>
            )}
        </Container>
    )
}

export default GuideCards
