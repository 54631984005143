import { Button } from "@mui/material";
import React from "react";

export default function TagButton({
    text,
    id = '',
    color = "primary",
    endIcon = null,
    href = "",
    onClick = () => { },
    sx = {},
    startIcon = null,
}) {
    return (
        <Button
            color={color}
            id={id}
            href={href}
            onClick={onClick}
            sx={{
                borderRadius: "16px",
                color: "#ffffff",
                px: ".5rem",
                py: "2px",
                textTransform: "none",
                ...sx,
            }}
            variant="contained"
            startIcon={startIcon}
            endIcon={endIcon}
        >
            {text}
        </Button>
    )
}