import React from "react";

import { selectTours } from "../../app/features/cartSlice";
import { useSelector } from "react-redux";
import MainLayout from "../../components/MainLayout";
import { Box, Button, Container, Typography } from "@mui/material";
import ProductCard2 from "../../components/ProductCard2";
import './styles.css'
import bookingUrl from '../../assets/images/booking.png'
import { ReactComponent as Share } from "../../assets/icons/share.svg"
import { useScreenDetector } from "../../hooks/useScreenDetector";
import BookingProductCard from "./BookingProductCard";

const Booking = () => {
    var cartTours = useSelector(selectTours);
    const {isMobile} = useScreenDetector()
    cartTours = cartTours.map(item => ({ ...item, edit: false, remove: false }))

    return (
        <MainLayout>
            <Box id="background-container">
                <Container
                    sx={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}
                >
                    <Box
                        sx={{
                            my: '30px',
                            p: {xs: '15px 0px', md: '15px 40px'},
                            borderRadius: '8px',
                            width: '100%',
                            backgroundColor: '#FFFFFF'
                        }}
                    >   
                        <Box sx={{
                            display: 'block',
                            textAlign: 'center',
                            mb: '40px'
                        }}>
                            <img
                                alt="toursprivados.com"
                                src={bookingUrl}
                                style={{
                                    width: '124px',
                                    height: '124px'
                                }}
                            />
                            <Typography
                                variant="h5"
                                color="secondary"
                                sx={{
                                    fontSize: '16px',
                                    mb: '10px'
                                }}
                            >
                                ¡Tu reserva está hecha!
                            </Typography>
                            <Typography
                                variant="body2"
                                color="p"
                                sx={{
                                    fontSize: '12px',
                                    mb: '10px'
                                }}
                            >
                                Lorem ipsum dolor sit amet consectetur. Felis sit tempus email@email.com Dui tortor orci fusce facilisi. Fames lorem at pulvinar pulvinar et mi est sed
                            </Typography>
                            <Box sx={{
                                display: {xs: 'grid', md: 'block'}
                            }}>
                                <Button
                                    variant="outlined"
                                    sx={{
                                        color: 'primary.main'
                                    }}
                                >Descargar PDF</Button>
                                <Button
                                    color="secondary"
                                    variant="contained"
                                    sx={{
                                        ml: { xs: '0px', md: '15px' },
                                        mt: { xs: '15px', md: 0}
                                    }}
                                >
                                    Compartir&nbsp;
                                    <Share />
                                </Button>
                            </Box>
                        </Box>
                        <Typography
                            variant="h5"
                            color="secondary"
                            sx={{
                                fontSize: '16px',
                                mb: '10px'
                            }}
                        >
                            Resumen de compra
                        </Typography>
                        { cartTours.map(item => (
                            isMobile ? <BookingProductCard key = {item.id } data = { item } isCartModal = { true} />
                            : <ProductCard2 key={item.id} data={item} isCart={true} />))}
                        <Box sx={{
                            display: {xs: 'grid', md: 'block'},
                            textAlign: 'center',
                            mt: '20px',
                            mb: '10px'
                        }}>
                            <Button
                                href="/"
                                color="primary"
                                variant="contained"
                            >Seguir navegando</Button>
                        </Box>
                    </Box>
                </Container>
            </Box>
        </MainLayout>
    )
}

export default Booking;