import React, { useCallback, useEffect, useState } from 'react'

import { Alert, Box, Button, Grid, Link, Typography } from '@mui/material'
import { ReactComponent as User } from '../../../assets/icons/user.svg'
import AccordionAssitant from './accordionAssitant'
import { selectTours, selectAssitants } from '../../../app/features/cartSlice'
import { useSelector } from 'react-redux'
import Agreements from './agreements'
import FormTitular from './formTitular'
import Resumen from './resumen'
import CardResumen from '../cardResumen'

const Step2 = ({ handleNext }) => {
    const cartTours = useSelector(selectTours)
    const assistants = useSelector(selectAssitants)
    const [agreement1, setAgreement1] = useState(false)
    const [agreement2, setAgreement2] = useState(false)

    const handleAgreements = () => {}

    const [formData, setFormData] = useState({
        name: '',
        lastName: '',
        email: '',
        confirmEmail: '',
        passport: '',
        phone: ''
    })

    const [errors, setErrors] = useState({
        name: '',
        lastName: '',
        email: '',
        confirmEmail: '',
        passport: '',
        phone: ''
    })

    const [isFormValid, setIsFormValid] = useState(false)

    const handleChange = (e) => {
        const { name, value } = e.target
        setFormData({
            ...formData,
            [name]: value
        })
    }

    const validateForm = useCallback(() => {
        const newErrors = {
            name: '',
            lastName: '',
            email: '',
            confirmEmail: '',
            passport: '',
            phone: ''
        }

        let isValid = true

        if (!formData.name) {
            newErrors.name = 'El nombre es requerido'
            isValid = false
        }

        if (!formData.lastName) {
            newErrors.lastName = 'Los apellidos son requeridos'
            isValid = false
        }

        if (!formData.email) {
            newErrors.email = 'El email es requerido'
            isValid = false
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = 'El email no es válido'
            isValid = false
        }
        if (!formData.confirmEmail) {
            newErrors.confirmEmail = 'El email es requerido'
            isValid = false
        } else if (!/\S+@\S+\.\S+/.test(formData.confirmEmail)) {
            newErrors.confirmEmail = 'El email no es válido'
            isValid = false
        }
        if (!formData.passport) {
            newErrors.passport = 'El pasaporte es requerido'
            isValid = false
        }
        if (!formData.phone) {
            newErrors.phone = 'El telefono es requerido'
            isValid = false
        }

        setErrors(newErrors)
        setIsFormValid(isValid)
    }, [formData])

    useEffect(() => {
        validateForm()
    }, [validateForm])

    const handleChangeAssistant = () => {}

    return (
        <Grid container spacing={3} sx={{ my: '30px' }}>
            <Grid item xs={12} md={8}>
                <Box
                    sx={{
                        backgroundColor: '#ffffff',
                        py: '15px',
                        px: { xs: 0, md: '15px' }
                    }}>
                    <Alert icon={<User />} variant="outlined" severity="info">
                        <Link
                            sx={{
                                fontSize: { xs: '12px', md: '17px' },
                                fontWeight: '600'
                            }}
                            href="#"
                            underline="none">
                            ¿Ya eres cliente? Haz clic aquí para acceder
                        </Link>
                    </Alert>
                    <Box sx={{ pt: '25px' }}>
                        <Typography
                            variant="h5"
                            color="secondary"
                            sx={{
                                fontSize: '18px',
                                pb: '10px'
                            }}>
                            Datos del titular
                        </Typography>
                        <FormTitular
                            formData={formData}
                            handleChange={handleChange}
                        />
                    </Box>
                </Box>
                <Box
                    sx={{
                        backgroundColor: '#ffffff',
                        py: '15px',
                        px: { xs: 0, md: '15px' },
                        mt: '20px',
                        borderRadius: '8px'
                    }}>
                    <Typography
                        variant="h5"
                        color="secondary"
                        sx={{
                            fontSize: '18px',
                            pb: '10px'
                        }}>
                        Añade los datos de los asistentes
                    </Typography>
                    <Typography
                        variant="body2"
                        component="p"
                        color="secondary"
                        sx={{ pb: '10px' }}>
                        Algunas atracciones exigen la documentación de todos los
                        asistentes. Si no puedes incluir esa documentación
                        ahora, podrás aportarla más adelante con tu operador
                        turístico.
                    </Typography>
                    {assistants.map((assistant, index) => (
                        <AccordionAssitant
                            key={index}
                            assitant={assistant}
                            tours={cartTours}
                            handleChange={handleChangeAssistant}
                            index={index}
                        />
                    ))}
                </Box>
                <Box
                    sx={{
                        my: '20px'
                    }}>
                    <Agreements
                        handleChange={handleAgreements}
                        agreement1={agreement1}
                        agreement2={agreement2}
                    />
                </Box>
                <Box
                    sx={{
                        display: { xs: 'none', md: 'flex' }
                    }}>
                    <Button
                        variant="contained"
                        onClick={handleNext}
                        disabled={!isFormValid}
                        sx={{
                            width: '260px'
                        }}>
                        Continuar
                    </Button>
                    <Button
                        color="secondary"
                        variant="contained"
                        sx={{
                            ml: '20px',
                            width: '160px'
                        }}>
                        Guardar carrito
                    </Button>
                </Box>
            </Grid>

            <Grid
                item
                xs={12}
                md={4}
                sx={{
                    display: {
                        xs: 'none',
                        md: 'block'
                    }
                }}>
                <Resumen cartTours={cartTours} />
            </Grid>
            <Grid
                item
                xs={12}
                md={4}
                sx={{
                    pl: { xs: '0!important' },
                    display: { xs: 'block', md: 'none' },
                    width: '100%'
                }}>
                <CardResumen
                    isFormValid={isFormValid}
                    handleNext={handleNext}
                />
            </Grid>
        </Grid>
    )
}

export default Step2
