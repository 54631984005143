import React from 'react'
import axios from 'axios'

const useFetchTourList = ({ name }) => {

    const [isLoading, setIsLoading] = React.useState(false);
    const [tours, setTours] = React.useState([]);
    const [error, setError] = React.useState(null);

    const fetchTourDetails = React.useCallback(async () => {
        setIsLoading(true)
        const { REACT_APP_BASE_URL } = process.env
        const token = localStorage.getItem('token');


        try {
            const { data } = await axios.get(
                `${REACT_APP_BASE_URL}/index.php?r=api/tours/tourlist&city=${name}`,
                {
                    headers: {
                        'x-authorization-token': token
                    }
                }
            )

            console.log('data', data)
            setIsLoading(false)

            if (data?.tours) {
                setTours(data.tours)
            }
        } catch (err) {
            console.error('Error fetching tour details ', err)
            setError(true)
            setIsLoading(false)
        }

    }, [name])
    //
    console.log('useFetchTourList', name)
    React.useEffect(() => {
        if (name) {  // Solo hacer fetch si `name` tiene un valor
            fetchTourDetails();
        }
    }, [fetchTourDetails]);

    return {
        tours,
        isLoading,
        error
    }
}

export default useFetchTourList


const mock = {
    "status": "success",
    "message": "Tour Details",
    "code": "200",
    "tour": [
        {
            "name": "Tour Privado Niagara ",
            "slug": "tour-privado-niagara",
            "code": "105827",
            "images": [
                {
                    "imagen": "https://s3.amazonaws.com/images.trekzy.com/2024/10/17/da76f2f6-0ca7-67ac-8bc4-19b96a1a0c69/FULL/whatsapp-image-2024-06-21-at-12.46.43.jpeg",
                    "caption": "",
                    "isCover": 0
                },
                {
                    "imagen": "https://s3.amazonaws.com/images.trekzy.com/2024/10/17/d312aefc-3e12-c6ad-b5ad-0cdb3352cc54/FULL/cataratas.webp",
                    "caption": "",
                    "isCover": 0
                },
                {
                    "imagen": "https://s3.amazonaws.com/images.trekzy.com/2024/10/17/8186f841-9db6-35fb-6bcf-c49ab1b44f4b/FULL/46626695ml.webp",
                    "caption": "",
                    "isCover": 0
                },
                {
                    "imagen": "https://s3.amazonaws.com/images.trekzy.com/2024/10/17/e28b61d6-bb40-44c9-3e2c-e5ba6448d3bb/FULL/31157576ml.webp",
                    "caption": "",
                    "isCover": 1
                },
                {
                    "imagen": "https://s3.amazonaws.com/images.trekzy.com/2024/10/17/02fd838b-704e-b5e4-16b9-1f2c30bb00a6/FULL/27801435ml.webp",
                    "caption": "",
                    "isCover": 0
                },
                {
                    "imagen": "https://s3.amazonaws.com/images.trekzy.com/2024/10/17/d99be30f-156e-d69c-56de-151f34169f5a/FULL/15172214ml.webp",
                    "caption": "",
                    "isCover": 0
                }
            ],
            "continent": {
                "name": "Europa",
                "slug": "Europa"
            },
            "country": {
                "name": "Estados Unidos",
                "slug": "Estados Unidos"
            },
            "region": {
                "name": "Nueva York",
                "slug": "Nueva York"
            },
            "city": {
                "name": "Niagara Falls",
                "slug": "Niagara Falls"
            },
            "scores": {
                "score": "9.2",
                "stars": "5"
            },
            "reviewsTotal": 1253,
            "availabilityDays": {
                "days": "2,3"
            },
            "availabilityHours": {
                "hours": "09:00"
            },
            "closedates": [],
            "maxPax": 15,
            "minPax": 1,
            "isAccessible": 0,
            "guideIncluded": 0,
            "margin": null,
            "isPrivate": null,
            "duration": {
                "duration": null,
                "type": null
            },
            "cutoff": {
                "value": 48,
                "type": "48h"
            },
            "rates": {
                "min_price": "200",
                "min_pax_name": "Adulto",
                "currency": "€",
                "paxes": {
                    "Infante": "100",
                    "Adulto": "200"
                }
            },
            "languages": [
                {
                    "language": "Spanish",
                    "code": "es"
                }
            ],
            "content": {
                "description": "<p><span style=\"color: rgb(0, 0, 0);\">Tu día comenzará temprano en Times Square, Manhattan, con un viaje en autobús cómodo junto a un guía turístico bilingüe, disfrutando del paisaje del norte del estado de Nueva York. El trayecto hacia las Cataratas del Niagara dura aproximadamente 7-8 horas, con una parada para descansar.</span></p><p><br></p><p><span style=\"color: rgb(0, 0, 0);\">Al llegar, vivirás la emoción de las Cataratas a bordo del crucero Maid of the Mist, o desde puntos de observación alternativos como la caminata Gorge Walk en la Cueva de los Vientos, dependiendo de la temporada y el clima. </span></p><p><br></p><p><span style=\"color: rgb(0, 0, 0);\">Durante la visita, descubrirás la historia de las cataratas, disfrutarás de impresionantes vistas, almorzarás por tu cuenta y explorarás el hermoso Parque Estatal de las Cataratas del Niagara.</span></p><p><br></p><p><span style=\"color: rgb(0, 0, 0);\">Luego de una experiencia inolvidable, te relajarás en el autobús de regreso a Times Square, llegando entre las 11 p.m. y medianoche</span></p><p><br></p><p><span style=\"color: rgb(0, 0, 0);\">Nota: El Maid of the Mist opera de mayo a octubre, dependiendo del hielo. Fuera de temporada, se ofrecen puntos de observación alternativos como Terrapin Point, sujetos a condiciones climáticas.</span></p>",
                "include": "<ul><li>Guía profesional bilingüe (inglés y español)</li><li>Traslado de ida y vuelta desde la ciudad de Nueva York en un cómodo autobús</li><li>Entradas para el crucero en barco Maid of the Mist (generalmente de mayo a octubre según las condiciones del hielo)&nbsp;Solo disponible con el Tour completo</li><li>Audioguía (si elige la opción)</li></ul>",
                "notIncluded": "<p>• Consejos opcionales</p><p>• Recogida y regreso al hotel</p><p>• Recuerdos</p><p>• Alimentos y bebidas</p>",
                "policyCancellation": "<ul><li>Cancelación gratuita hasta 48 horas antes del inicio del tour.</li></ul>",
                "requirement": "<ul><li>Recomendamos llevar ropa y calzado adecuado.</li><li>Recomendamos que revisen la climatología antes de salir. El tour se realiza al aire libre, por lo que se recomienda traer protección solar o ropa impermeable según el clima.</li></ul><p><br></p>",
                "instructions": "<ul><li>Por favor, necesitamos puntualidad por lo que es necesario que estén 10 minutos antes de la hora de salida en el punto de encuentro. El guia estará esperando con un cartel de Amigo Tours.</li><li>Si has elegido la opción con audioguía te enviaremos las instrucciones para descargarla antes de tu visita.</li></ul>",
                "additionalInformation": "<p>De acuerdo a las regulaciones de US, los niños menores de 3 años deberán ir sentados en sillitas, pero los autobuses no están obligados a facilitarlas. Amigo Tours no se hará responsable si no es posible subir al autobús. Para asegurar que su bebé tiene un asiento, deberá traer el suyo propio. Podemos intentar conseguir uno si nos avisan con suficiente antelación.</p>"
            },
            "extras": [
                {
                    "type": "food",
                    "included": false,
                    "price": 20,
                    "perPerson": false
                }
            ],
            "meetingpoint": [
                {
                    "lat": "40.7596193",
                    "lon": "-73.9846788",
                    "address": "714 7th Ave, New York, NY 10036, EE. UU.",
                    "pickup": ""
                }
            ]
        }
    ]
}
