import React from 'react'
import axios from 'axios'

const useFetchCountries = () => {
    const [countries, setCountries] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);

    const fetchCountryList = React.useCallback(async () => {
        setIsLoading(true)
        const { REACT_APP_BASE_URL } = process.env
        const token = localStorage.getItem('token');

        try {
            const { data } = await axios.get(
                `${REACT_APP_BASE_URL}/index.php?r=api%2Fdestinations`,
                {
                    headers: {
                        'x-authorization-token': token
                    }
                }
            ) || {}

            if (data.countries.length) {
                setCountries(data)
            }
            setIsLoading(false)
        } catch (err) {
            console.error('Error fetching destinations featured: ', err)
            setIsLoading(false)
        }
    }, [])

    React.useEffect(() => {
        fetchCountryList()
    }, [fetchCountryList])

    return {
        countries,
        isLoading,
    }
}

export default useFetchCountries
