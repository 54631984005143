import React from 'react'

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    FormControlLabel,
    Grid,
    TextField,
    Typography
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import BpRadio from '../BpRadio'

const AccordionAssitant = ({ assitant, tours, index, handleChange }) => {
    return (
        <Accordion
            sx={{
                border: '1px solid #223F7F',
                boxShadow: 'none',
                my: '10px',
                borderRadius: '8px!important'
            }}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index}-content`}
                id={`panel${index}-header`}
                sx={{
                    py: '6px',
                    color: 'secondary.main'
                }}>
                Pasajero {index + 1}
            </AccordionSummary>
            <AccordionDetails>
                <form>
                    <Grid
                        container
                        spacing={3}
                        sx={{
                            '& .MuiInputBase-input': {
                                p: '7px 10px',
                                bgcolor: '#F9FAFB',
                                border: '1px solid #F9FAFB'
                            }
                        }}>
                        <Grid item xs={12} md={6} sx={{ display: 'grid' }}>
                            <label htmlFor="name">Nombre*</label>
                            <TextField
                                name="name"
                                type="text"
                                placeholder="Introduce..."
                                value={assitant.name}
                                onChange={handleChange}
                                sx={{
                                    p: 0
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} md={6} sx={{ display: 'grid' }}>
                            <label htmlFor="secondName">Apellidos*:</label>
                            <TextField
                                name="lastName"
                                type="text"
                                placeholder="Introduce..."
                                value={assitant.lastName}
                                onChange={handleChange}
                            />
                        </Grid>

                        <Grid item xs={12} md={12} sx={{ display: 'grid' }}>
                            <label htmlFor="message">Pasaporte*</label>
                            <TextField
                                name="passport"
                                type="text"
                                placeholder="Introduce..."
                                value={assitant.passport}
                                onChange={handleChange}
                            />
                        </Grid>
                    </Grid>
                </form>
                <Box sx={{ pt: '20px' }}>
                    <Typography variant="body2" component="p">
                        ¿A qué tour va? (Señala los tours a los que está
                        incluido este asistente)*
                    </Typography>
                    <Box sx={{ pl: '10px', display: 'grid' }}>
                        <FormControlLabel
                            value="todos"
                            label="Todos los tours"
                            sx={{
                                '& .MuiFormControlLabel-label': {
                                    fontSize: '12px'
                                }
                            }}
                            control={
                                <BpRadio
                                    checked={true}
                                    onChange={handleChange}
                                    value="todos"
                                    name={`radio-buttons-todos`}
                                    inputProps={{ 'aria-label': 'Todos' }}
                                />
                            }
                        />
                        {tours.map((tour) => (
                            <FormControlLabel
                                key={tour.id}
                                value={tour.id}
                                label={tour.title}
                                sx={{
                                    '& .MuiFormControlLabel-label': {
                                        fontSize: '12px'
                                    }
                                }}
                                control={
                                    <BpRadio
                                        checked={false}
                                        onChange={handleChange}
                                        value={tour.id}
                                        name={`radio-buttons${tour.id}`}
                                        inputProps={{ 'aria-label': tour.id }}
                                    />
                                }
                            />
                        ))}
                    </Box>
                </Box>
            </AccordionDetails>
        </Accordion>
    )
}

export default AccordionAssitant
