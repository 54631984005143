import React, { useState } from "react";

import CheckoutLayout from "../../components/CheckoutLayout";
import { Box, Container, Link, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { selectTours } from "../../app/features/cartSlice";
import CartProductCard from "./CartProductCard";
import CardResumen from "./cardResumen";
import UpdateTourDialog from "../../components/UpdateTourDialog";
import { useScreenDetector } from "../../hooks/useScreenDetector";
import { remove } from "../../app/features/cartSlice";

const CartMobile = () => {
    const cartTours = useSelector(selectTours);
    const [open, setOpen] = useState(false);
    const [selectedTour, setSelectedTour] = useState(null);
    const { isMobile } = useScreenDetector()
    const dispatch = useDispatch();
    
    const handleClose = () => setOpen(false);

    const handleRemoveTour = (id) => dispatch(remove(id))

    const handleEditTour = (id) => {
        setSelectedTour(cartTours.find(tour => tour.id === id));
        setOpen(true);
    }

    return (
        <CheckoutLayout>
            {selectedTour && isMobile && <UpdateTourDialog open={open} handleClose={handleClose} tour={selectedTour}/>}
            <Container id="cart-mobile-container">
                {cartTours.map(item => (
                    <CartProductCard
                        key={item.id}
                        data={item}
                        handleEditTour={handleEditTour}
                        handleRemoveTour={handleRemoveTour}
                    />
                ))}
                <Box sx={{
                    my: '25px'
                }}>
                    <Link
                        sx={{
                            textDecoration: 'none'
                        }}
                    >
                        <Typography
                            variant="body2"
                            component="p"
                            sx={{
                                textAlign: 'center',
                                fontSize: '16px',
                                fontWeight: 700,
                                color: 'secondary.main',
                                
                            }}
                        >
                            ¿Vas a Turquía? Aquí hay algo que te puede interesar
                        </Typography>
                    </Link>
                </Box>
                <Box>
                    <CardResumen />
                </Box>
            </Container>
        </CheckoutLayout>
    )

}

export default CartMobile;