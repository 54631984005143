import React from 'react';
import { Link, Typography } from '@mui/material';
import BlurImageWrapper, { opacities, orientations } from '../BlurImageWrapper';
import { useScreenDetector } from '../../hooks/useScreenDetector';

const Card = ({
    image,
    href,
    title,
    tagText,
    bottomText,
}) => {
    const { isMobile } = useScreenDetector();
    return (
        <Link href={href} sx={{
            textDecoration: 'none',
            width: '100%',
        }}>
            <BlurImageWrapper
                imageUrl={image}
                orientation={isMobile ? orientations.rightToLeft : orientations.bottomToTop}
                opacity={isMobile ? opacities.dark : opacities.lighter}
                styles={{
                    borderRadius: '10px',
                    padding: '1rem',
                    width: '100%',
                }}
            >
                <Typography
                    color='primary.contrastText'
                    component='h3'
                    variant='h5'
                    sx={{
                        fontSize: { xs: 20, md: 24 },
                        fontWeight: 'bold',
                        pt: { xs: 0, md: '8rem' }
                    }}
                >
                    {title}
                </Typography>
                {tagText ? (
                    <Typography
                        color='primary.contrastText'
                        sx={{
                            backgroundColor: '#40C5E8',
                            borderRadius: 4,
                            fontSize: { xs: 14.75, md: 20 },
                            fontFamily: 'Poppins',
                            fontWeight: 'bold',
                            lineHeight: 1.2,
                            mb: '.2rem',
                            py: '3px',
                            px: '8px',
                            width: 'fit-content'
                        }}
                    >
                        {tagText}
                    </Typography>
                ) : null }
                <Typography
                    color='primary.contrastText'
                    sx={{
                        fontSize: { xs: 16, md: 18 },
                        fontFamily: 'Inter',
                        fontWeight: { sx: 'regular', md: 'bold' },
                        lineHeight: 1.2,
                    }}
                >
                    {bottomText}
                </Typography>
            </BlurImageWrapper>
        </Link>
    )
}

export default Card
