export const dataPage = {
   hero: {
        country: 'Turquia',
        city: 'Estambul',
        numberComments: '188',
        media: '9,9/10',
        numberActivities: '104'
    },
    breadcrumbs: [
        {
            text: 'Home',
            href: '/',
            isCurrent: false
        },
        {
            text: 'Destinos',
            href: '/destinos',
            isCurrent: false
        },
        {
            text: 'Turquia',
            href: '/destinos/turquia',
            isCurrent: false
        },
        {
            text: 'Estambul',
            href: '#',
            isCurrent: true
        }
    ],
    activities: [
        {
            imageUrl: 'images/kalalau.png',
            ivaIncluded: true,
            rate: 9,
            price: 150.5,
            reviews: {
                state: 'Excelente',
                number: 9378
            },
            description: `Lorem ipsum dolor sit amet consectetur. Aliquet amet in proin nibh. 
            Habitasse metus quis at est platea sem venenatis pellentesque. Commodo velit nisl
            diam magnis massa volutpa.`,
            title: 'Estambul nocturno: Sofía y Mezquita Azul',
            path: "/destinos/turquia/estambul/estambul-nocturno-sofia-mezquita-azul"
        },
        {
            imageUrl: 'images/kalalau.png',
            ivaIncluded: true,
            rate: 9,
            price: 150.5,
            reviews: {
                state: 'Excelente',
                number: 9378
            },
            description: `Lorem ipsum dolor sit amet consectetur. Aliquet amet in proin nibh. 
            Habitasse metus quis at est platea sem venenatis pellentesque. Commodo velit nisl
            diam magnis massa volutpa.`,
            title: 'Estambul nocturno: Sofía y Mezquita Azul',
            path: "/destinos/turquia/estambul/estambul-nocturno-sofia-mezquita-azul"
        },
        {
            imageUrl: 'images/kalalau.png',
            ivaIncluded: true,
            rate: 9,
            price: 150.5,
            reviews: {
                state: 'Excelente',
                number: 9378
            },
            description: `Lorem ipsum dolor sit amet consectetur. Aliquet amet in proin nibh. 
            Habitasse metus quis at est platea sem venenatis pellentesque. Commodo velit nisl
            diam magnis massa volutpa.`,
            title: 'Estambul nocturno: Sofía y Mezquita Azul',
            path: "/destinos/turquia/estambul/estambul-nocturno-sofia-mezquita-azul"
        },
        {
            imageUrl: 'images/kalalau.png',
            ivaIncluded: true,
            rate: 9,
            price: 150.5,
            reviews: {
                state: 'Excelente',
                number: 9378
            },
            description: `Lorem ipsum dolor sit amet consectetur. Aliquet amet in proin nibh. 
            Habitasse metus quis at est platea sem venenatis pellentesque. Commodo velit nisl
            diam magnis massa volutpa.`,
            title: 'Estambul nocturno: Sofía y Mezquita Azul',
            path: "/destinos/turquia/estambul/estambul-nocturno-sofia-mezquita-azul"
        },
        {
            imageUrl: 'images/kalalau.png',
            ivaIncluded: true,
            rate: 9,
            price: 150.5,
            reviews: {
                state: 'Excelente',
                number: 9378
            },
            description: `Lorem ipsum dolor sit amet consectetur. Aliquet amet in proin nibh. 
            Habitasse metus quis at est platea sem venenatis pellentesque. Commodo velit nisl
            diam magnis massa volutpa.`,
            title: 'Estambul nocturno: Sofía y Mezquita Azul',
            path: "/destinos/turquia/estambul/estambul-nocturno-sofia-mezquita-azul"
        },
        {
            imageUrl: 'images/kalalau.png',
            ivaIncluded: true,
            rate: 9,
            price: 150.5,
            reviews: {
                state: 'Excelente',
                number: 9378
            },
            description: `Lorem ipsum dolor sit amet consectetur. Aliquet amet in proin nibh. 
            Habitasse metus quis at est platea sem venenatis pellentesque. Commodo velit nisl
            diam magnis massa volutpa.`,
            title: 'Estambul nocturno: Sofía y Mezquita Azul',
            path: "/destinos/turquia/estambul/estambul-nocturno-sofia-mezquita-azul"
        },
        {
            imageUrl: 'images/kalalau.png',
            ivaIncluded: true,
            rate: 9,
            price: 150.5,
            reviews: {
                state: 'Excelente',
                number: 9378
            },
            description: `Lorem ipsum dolor sit amet consectetur. Aliquet amet in proin nibh. 
            Habitasse metus quis at est platea sem venenatis pellentesque. Commodo velit nisl
            diam magnis massa volutpa.`,
            title: 'Estambul nocturno: Sofía y Mezquita Azul',
            path: "/destinos/turquia/estambul/estambul-nocturno-sofia-mezquita-azul"
        },
        {
            imageUrl: 'images/kalalau.png',
            ivaIncluded: true,
            rate: 9,
            price: 150.5,
            reviews: {
                state: 'Excelente',
                number: 9378
            },
            description: `Lorem ipsum dolor sit amet consectetur. Aliquet amet in proin nibh. 
            Habitasse metus quis at est platea sem venenatis pellentesque. Commodo velit nisl
            diam magnis massa volutpa.`,
            title: 'Estambul nocturno: Sofía y Mezquita Azul',
            path: "/destinos/turquia/estambul/estambul-nocturno-sofia-mezquita-azul"
        }
    ],
    description: `Lorem ipsum dolor sit amet consectetur. Id morbi purus blandit mi. Sed 
    fermentum amet leo pellentesque sapien sit elementum in. Tellus amet proin odio amet
    vitae sodales magna mus. Aliquam tristique ut quis turpis vel sed Leer más.`,
    numberActivities: 104,
    content: [
        {
            value: 'Estambul nocturno: Sofía y Mezquita Azul',
            variant: 'h5',
            component: 'h5'
        },
        {
            value: `Lorem ipsum dolor sit amet consectetur. Placerat feugiat volutpat lorem tincidunt lectus sapien. 
            Libero sapien donec in congue lacus ornare consequat. Nulla risus.Lorem ipsum dolor sit amet consectetur.
            Placerat feugiat volutpat lorem tincidunt lectus sapien. Libero sapien donec in congue lacus ornare consequat.
            Nulla risus.Lorem ipsum dolor sit amet consectetur. Placerat feugiat volutpat lorem tincidunt lectus sapien. 
            Libero sapien donec in congue lacus ornare consequat. Nulla risus.<br>
            Lorem ipsum dolor sit amet consectetur. Placerat feugiat volutpat lorem tincidunt lectus sapien. 
            Libero sapien donec in congue lacus ornare consequat. Nulla risus.Lorem ipsum dolor sit amet consectetur.
            Placerat feugiat volutpat lorem tincidunt lectus sapien. Libero sapien donec in congue lacus ornare consequat.
            Nulla risus.Lorem ipsum dolor sit amet consectetur. Placerat feugiat volutpat lorem tincidunt lectus sapien. 
            Libero sapien donec in congue lacus ornare consequat. Nulla risus.`,
            variant: 'body2',
            component: 'p'
        },
        {
            value: 'Estambul nocturno: Sofía y Mezquita Azul',
            variant: 'h5',
            component: 'h5'
        },
        {
            value: `Lorem ipsum dolor sit amet consectetur. Placerat feugiat volutpat lorem tincidunt lectus sapien. 
            Libero sapien donec in congue lacus ornare consequat. Nulla risus.Lorem ipsum dolor sit amet consectetur.
            Placerat feugiat volutpat lorem tincidunt lectus sapien. Libero sapien donec in congue lacus ornare consequat.
            Nulla risus.Lorem ipsum dolor sit amet consectetur. Placerat feugiat volutpat lorem tincidunt lectus sapien. 
            Libero sapien donec in congue lacus ornare consequat. Nulla risus.`,
            variant: 'body2',
            component: 'p'
        },
    ],
    atracciones: {
        atraccionesLinks: [
            {
                text: 'Santa Sofia',
                href: '#'
            },
            {
                text: 'Palacio de Topkapi',
                href: '#'
            },
            {
                text: 'Cisterna Basílica',
                href: '#'
            },
            {
                text: 'Cisterna Basílica',
                href: '#'
            },
            {
                text: 'Cisterna Basílica',
                href: '#'
            },
            {
                text: 'Cisterna Basílica',
                href: '#'
            },
            {
                text: 'Cisterna Basílica',
                href: '#'
            },
            {
                text: 'Cisterna Basílica',
                href: '#'
            },
            {
                text: 'Cisterna Basílica',
                href: '#'
            },
            {
                text: 'Palacio de Dolmabahçe',
                href: '#'
            }
        ],
        title: 'Atracciones en Estambul'
    },
    guias: {
        guiasLinks: [
            {
                text: 'Ver guía de viaje de Estambul',
                href: '#'
            },
        ],
        description: `Lorem ipsum dolor sit amet consectetur. Volutpat enim imperdiet nulla 
        dignissim at. Ultrices est fusce odio pulvinar facilisis montes egestas ultrices.`,
        title: 'Guías de viaje de Estambul'


    }
}

export const atracciones = [
    {
        id: 'atraccion1',
        name: 'Mercado de las especias',
        value: 23,
        isChecked: false
    },
    {
        id: 'atraccion2',
        name: 'Gran bazar',
        value: 23,
        isChecked: false
    },
    {
        id: 'atraccion3',
        name: 'Mezquita azul',
        value: 23,
        isChecked: false
    },
    {
        id: 'atraccion4',
        name: 'Santa Sofia',
        value: 23,
        isChecked: true
    },
    {
        id: 'atraccion5',
        name: 'Catillo de Topkapi',
        value: 23,
        isChecked: false
    }
];

export const categorias = [
    {
        id: 'categoria1',
        name: 'Gastronomia',
        value: 23,
        isChecked: false
    },
    {
        id: 'categoria2',
        name: 'Museos',
        value: 23,
        isChecked: false
    },
    {
        id: 'categoria3',
        name: 'Naturaleza',
        value: 23,
        isChecked: false
    },
    {
        id: 'categoria4',
        name: 'Ciudad',
        value: 23,
        isChecked: true
    },
    {
        id: 'categoria5',
        name: 'Historia',
        value: 23,
        isChecked: false
    }
];

export const duraciones = [
    {
        id: 'duracion1',
        name: 'Menos de 1h',
        value: 23,
        isChecked: false
    },
    {
        id: 'duracion2',
        name: '1h - 3h',
        value: 23,
        isChecked: false
    },
    {
        id: 'duracion3',
        name: '+3h',
        value: 23,
        isChecked: false
    },
    {
        id: 'duracion4',
        name: '1d',
        value: 23,
        isChecked: true
    },
    {
        id: 'duracion5',
        name: '+2d',
        value: 23,
        isChecked: false
    }
];

export const timeSlider = {
  marks: [
  {
    value: 10,
    label: '10h'
  },
  {
    value: 19,
    label: '19h'
    }
  ],
  max: 19,
  min: 10,
  ariaLabel1: 'Minimum time',
  ariaLabel2: 'Maximum time',
}

export const priceSlider = {
  marks: [
  {
    value: 0,
    label: 'Gratis'
  },
  {
    value: 160,
    label: '160€'
    }
  ],
  max: 160,
  min: 0,
  ariaLabel1: 'Minimum price',
  ariaLabel2: 'Maximum price',
}
