import React from "react"
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    Typography
} from "@mui/material"
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

const GroupCheckBoxesFilter = ({title, checkBoxes, handleOnChange}) => (
    <Accordion defaultExpanded sx={{mb: '25px', borderRadius: '8px'}}>
        <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls={`${title}-content`}
            id={`${title}-header`}
        >
            <Typography>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{height: '240px', overflowX: 'scroll'}}>
            <FormControl component="fieldset" variant="standard">
                <FormGroup>
                    {checkBoxes.map(checkBox => (
                        <FormControlLabel
                            key={checkBox.id}
                            control={
                                <Checkbox
                                    checked={checkBox.isChecked}
                                    onChange={() => handleOnChange(checkBox.id)}
                                    name={checkBox.name}
                                />
                            }
                            label={checkBox.name}
                        />
                    ))}
                </FormGroup>
            </FormControl>
        </AccordionDetails>
    </Accordion>
)
export default GroupCheckBoxesFilter
